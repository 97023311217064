.form-group.upload-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
}
.upload-block label {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  padding-right: 15px;
}
.upload-block .upload-button {
  display: inline-block;
  width: auto;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  position: relative;
}
.upload-block .upload-button .custom-file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 9;
}

.sample-csv-class {
  display: flex;
  justify-content: space-between;
}

.vdp-merge-listing-table {
  min-height: 300px;
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
}
