.import-template-main-block {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.import-template-main-block .card {
  margin-bottom: 16px;
}

.import-template-main-block .card-body {
  padding: 1.25rem;
}

.select-import-template {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
}
.select-import-template .import-template-form {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  margin-right: 50px;
  max-width: 600px;
}

.select-import-template .import-template-form .form-group {
  margin-bottom: 0;
}

.import-template-main-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.import-template-main-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

/*////*/

.crm-contacts_block {
  width: 100%;
  padding: 0;
  margin: 0;
  outline: none;
  border: 1px solid #545b62;
}

.crm-contacts_block .nav-tabs {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.crm-contacts_block .nav-tabs li {
  display: inline-block;
  padding: 2px 1px;
  margin: 0;
  border: none;
  list-style: none;
}
.crm-contacts_block .nav-tabs li .nav-link {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0;
  box-shadow: none;
  background: #545b62;
  border: none;
  outline: none;
  font-size: 18px;
}

.crm-contacts_block .nav-tabs li .active.nav-link {
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
}

.crm-tabcontacts_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  margin: 0;
  border: none;
  outline: none;
}

.crm-tabcontacts_block .left-crm-contacts-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.crm-tabcontacts_block .drag-and-drop-block {
  width: 100%;
  padding: 0 0 0 15px;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-width: 30%;
  border-left: 1px solid #545b62;
}

.crm-tabcontacts_block .crm-contacts-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-height: 70vh;
  overflow: auto;
}
.crm-tabcontacts_block .drag-drop-all-items {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-height: calc(70vh - 70px);
  overflow: auto;
}

.crm-tabcontacts_block .drag-drop-all-items .drag-drop-item-list {
  width: 85%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.drag-drop-all-items .drag-drop-item-list li {
  width: 100%;
  padding: 4px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.contacts-list-item {
  width: 100%;
  padding: 4px;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contacts-list-item label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  max-width: 250px;
  margin-right: 10px;
}
.contacts-list-item .drag-drop-place-box {
  width: 100%;
  padding: 0;
  margin: 0 15px 0 0;
  border: none;
  outline: none;
  text-decoration: none;
  position: relative;
}
.crm-tabcontacts_block .drag-drop-item {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-align: center;
}
.contacts-list-item .drag-drop-place-box.active .drag-drop-item {
  background-color: #495057;
  border-color: #495057;
  color: #ffffff;
}

.contacts-list-item .drag-drop-place-box .drag-drop-item.active {
  background-color: #495057;
  border-color: #495057;
  color: #ffffff;
}

.contacts-list-item .close-button {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #495057;
  border: none;
  outline: none;
  color: #ffffff;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  font-size: 16px;
  transform: translateZ(0);
  font-weight: 300;
}
.contacts-list-item .close-button i {
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: normal;
}
.contacts-list-item .close-button:hover {
  text-decoration: none;
  background: #545cd8;
}

.crm-tabcontacts_block .drag-and-drop-block .titel {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 18px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  margin-right: 10px;
}
.crm-tabcontacts_block .drag-and-drop-block p {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 11px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
}

.drag-drop-all-items .drag-drop-item-list li .drag-drop-item {
  background-color: #495057;
  border-color: #495057;
  color: #ffffff;
  cursor: pointer;
}

.drag-drop-all-items .drag-drop-item-list li .drag-drop-item.inactive {
  background-color: #545cd8;
  border-color: #545cd8;
  color: #ffffff;
}

.contacts-button-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 30px 0;
}
.contacts-button-block .button-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.crm-contacts-mainblock * {
  scrollbar-width: thin;
  scrollbar-color: #cad1d7 #ffffff;
}

.crm-contacts-mainblock ::-webkit-scrollbar {
  width: 12px;
}

.crm-contacts-mainblock ::-webkit-scrollbar-track {
  background: #ffffff;
}

.crm-contacts-mainblock ::-webkit-scrollbar-thumb {
  background-color: #cad1d7;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

/*//////notification-popup-block/////*/

.notification-modal .modal-content .modal-header {
  background-color: #eff3f6;
}

.notification-modal .modal-button-block {
  flex-wrap: wrap;
}
.notification-modal .modal-button-block button {
  margin: 8px;
}

.notification-popup-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.notification-popup-block .sub-titel {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.notification-popup-block .missing-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow: auto;
}
.notification-popup-block .missing-list li {
  display: flex;
  padding: 4px 8px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
.notification-popup-block .missing-list li::before {
  content: "\ea33";
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
  color: #990000;
}

.notification-popup-block .missing-list li:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.notification-popup-block .info-text {
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  background-color: #eff3f6;
  font-style: italic;
}

.notification-popup-block ul.list {
  width: 100%;
  padding: 0;
  margin: 8px 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow: auto;
}
.notification-popup-block ul.list li {
  display: flex;
  padding: 8px 8px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
.notification-popup-block ul.list li::before {
  content: "\ea11";
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
}

.notification-popup-block ul.list li:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.notification-popup-block ul.list li b {
  display: inline-block;
  font-weight: bold;
  padding-right: 8px;
}

.drag-drop-item.validation-error {
  border-color: red;
  border: solid 1px red;
}
