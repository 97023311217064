@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.red_err {
  color: red;
  font-weight: bold;
}
.logo {
  margin: 0 auto !important;
  width: 290px;
}

.login-box .form-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0;
  line-height: 24px;
}
.login-box .form-group input[type="radio"],
input[type="checkbox"] {
  position: static;
  margin-right: 10px !important;
}
.login-box .form-check .form-check-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}

.text-blue-pwd {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0;
  margin: 0;
  color: #587fff !important;
  cursor: pointer;
}

.forgot-password-div {
  width: 100%;
  padding: 8px 0px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
