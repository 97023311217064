.payment-methods-main-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.payment-methods-main-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.payment-methods-main-block .card {
  margin-bottom: 30px;
}
.payment-methods-main-block .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #fafbfc;
}
.payment-methods-main-block .card-header .form-check {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 12px;
}
.payment-methods-main-block .card-header .form-check input[type="radio"],
input[type="checkbox"] {
  position: static;
  width: 18px;
  height: 18px;
}

.payment-methods-main-block .card-body {
  padding: 1.25rem;
}

.payment-methods-main-block .form-group {
  margin-bottom: 8px;
}

.payment-methods-main-block .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.payment-methods-main-block .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

/*////*/
.payment-methods-right-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.payment-methods-right-box .card-header {
  background: none;
}

.payment-methods-right-box .card-footer {
  background-color: #ffffff;
  padding: 0 0 1.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.payment-methods-right-box .order-view-button-block {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-methods-right-box .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  height: 35px;
  background: #545cd8;
  border: none;
  outline: none;
  box-shadow: none;
}

.payment-methods-right-box .add-drop-button-block .btn i {
  font-size: 18px;
  padding-right: 4px;
}

.payment-methods-right-box .btn.btn-secondary {
  background-color: #495057;
}

.payment-methods-right-box .btn.btn-success {
  color: #fff;
  background-color: #3ac47d;
  border-color: #3ac47d;
  box-shadow: none;
}

.payment-methods-right-box .btn.btn-success:hover {
  color: #fff;
  background-color: #31a66a;
  border-color: #2e9d64;
  box-shadow: 0 0.125rem 0.625rem rgb(52 58 64 / 40%),
    0 0.0625rem 0.125rem rgb(52 58 64 / 50%);
}

.payment-methods-right-box .wallet-balance {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #545cd8;
  font-weight: 600;
  line-height: normal;
}
.payment-methods-right-box .wallet-balance .card-title {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #545cd8;
  font-weight: bold;
  line-height: normal;
}

/*/////*/
.Payment-history-table-block .table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.profile-right-box .table thead th {
  border-bottom: 1px solid #e9ecef;
  font-size: 14px;
  font-weight: bold;
}

.Payment-history-table-block .table thead {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e9ecef;
  padding: 0.55rem;
  line-height: normal;
  position: relative;
  font-weight: 700;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  color: #545cd8;
}

.Payment-history-table-block tbody tr {
  border-bottom: 1px solid #e9ecef;
}

.Payment-history-table-block .table tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.Payment-history-table-block .table td {
  border-right: 1px solid #e9ecef;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #495057;
  text-align: left;
  word-wrap: break-word;
  padding: 4px 8px !important;
  vertical-align: middle;
  vertical-align: middle;
  font-weight: 600;
}
.Payment-history-table-block .table td:last-child {
  text-align: right;
}
.Payment-history-table-block .table th {
  font-weight: bold;
  padding: 4px 8px !important;
  vertical-align: middle;
}

.Payment-history-table-block .table .dropdown .btn {
  display: inline-block;
  padding: 2px 8px;
  margin: 0;
  border: none !important;
  outline: none;
  text-decoration: none;
  color: #495057;
  box-shadow: none;
  background-color: transparent !important;
}

.Payment-history-table-block .viewmore-button-block {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Payment-history-table-block .viewmore-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.payment-methods-main-block.Payment-history-table-block
  .viewmore-button-block
  .btn
  i {
  font-size: 14px;
  padding-left: 4px;
}

/*/////////*/

.payment-methods-box .form-box .form-check {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #495057;
  text-align: left;
  word-wrap: break-word;
  padding: 0;
  vertical-align: middle;
}

.payment-methods-box .form-box .form-check:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.payment-methods-box .form-box .form-check .form-check-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid #e9ecef;
}

.payment-methods-box .form-box .form-check .form-check-label .form-check-input {
  position: static;
  margin: 0 15px 0 0;
  width: 24px;
  height: 24px;
}

.payment-methods-box .form-box .form-check .payment-method-name {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*//////////*/

.add-new-payment-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.add-new-payment-block .add-new-payment-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.add-new-payment-box .btn {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.add-new-payment-box .add-new-payment-button {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.add-new-payment-box .add-new-payment-button i {
  display: inline-block;
  font-size: 28px;
  color: #ffffff;
}
.add-new-payment-box .add-new-payment-button span {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 4px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
}

.add-new-payment-box .btn:hover {
  text-decoration: none;
  background: #31a66a;
}

/*////popup///////*/

.add-card-popup-form {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: #ffffff;
}

.add-card-popup-form .modal-content {
  margin-bottom: 30px;
}
.modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #fafbfc;
}

.modal-content .modal-header .modal-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.modal-content .modal-body {
  padding: 1.25rem;
  background-color: #ffffff;
}

.add-card-popup-form .form-group {
  margin-bottom: 8px;
}

.add-card-popup-form .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.add-card-popup-form .card-popup-button-block {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-card-popup-form .card-popup-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: none;
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.add-card-popup-form .card-popup-button-block .btn.btn-secondary {
  background-color: #495057;
}

.add-card-popup-form .card-popup-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}


/*/////////*/

.payment-history-main-block{
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    text-decoration: none;
}
.payment-history-main-block .card{
    margin-bottom: 30px;
}
.payment-history-main-block .card-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.25rem;
    background-color:#fafbfc;
}

.payment-history-main-block .card-body{
    padding: 1.25rem;
}

.payment-history-main-block .form-group {
    margin-bottom: 8px;
}

.payment-history-main-block .card-header .card-title{
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: 20px;
    color: #495057;
    font-weight: 600;
    line-height: normal;
}

.payment-history-main-block .form-group label{
    width: 100%;
    padding: 0 0 8px 0;
    margin: 0;
    font-size: 14px;
    color: #495057;
    font-weight: 600;
    line-height: normal;
}



.popup-payment-source .form-check {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #495057;
  text-align: left;
  word-wrap: break-word;
  padding: 0;
  vertical-align: middle;
}

.popup-payment-source .form-check:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.payment-methods-box .form-box .form-check .form-check-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid #e9ecef;
}

.popup-payment-source .form-check .form-check-label .form-check-input {
  position: static;
  margin: 0 15px 0 0;
  width: 24px;
  height: 24px;
}

.popup-payment-source .form-check .payment-method-name {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-payment-source .form-check .form-check-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid #e9ecef;
}
.popup-payment-source{
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.popup-payment-source fieldset legend{
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.or-block{
  width: 100%;
  padding: 0;
  margin: 16px 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.or-block::after{
    content: "";
    width: 100%;
    height: 2px;
    background-color: #525f7f;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
    opacity: 0.5;
}
.or-block span{
  background-color: #fff;
  padding: 8px 16px;
  color: #495057;
  position: relative;
  z-index: 9;
  font-weight: 600;
}

.add-card-popup-form .paypale-button{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  margin: 0;
  text-align: center;
}
.add-card-popup-form .paypale-button .btn {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent !important;
  cursor: pointer;
  max-width: 200px;
  height: auto;
  box-shadow: none !important;
}

.add-card-popup-form .paypale-button .btn img{
  display: inline-block;
  max-width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.accountForm{
  width: 33em !important;
}

.Payment-history-table-block header .form-group{
  width: 100%;
}