.create-user-mainblock {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.create-user-mainblock .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-user-mainblock .form-group {
  margin-bottom: 8px;
}
.create-user-mainblock .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-user-mainblock legend {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-user-mainblock .titel {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.create-user-mainblock .set-sales-formbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0 0 4px 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.create-user-mainblock .set-sales-formbox .form-check {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.create-user-mainblock .set-sales-formbox .form-check label {
  font-weight: normal;
}
.create-user-mainblock .set-sales-formbox .form-check .form-control {
  display: inline-block;
  height: 28px;
}
.create-user-mainblock .set-sales-formbox .form-check:first-child {
  margin-right: 15px;
}

.create-user-mainblock .radio-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 0 0;
  margin: 0;
  border: none;
}
.create-user-mainblock .radio-block legend {
  width: auto;
  display: inline-block;
  padding: 0 15px 0 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-user-mainblock .radio-block .form-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  border: none;
}
.create-user-mainblock .radio-block .form-check {
  margin-right: 15px;
}
.create-user-mainblock .radio-block .form-check label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}
.create-user-mainblock .radio-block .form-check-input {
  width: 22px;
  height: 22px;
  position: static;
  margin-right: 8px;
}
.create-user-mainblock .radio-block .form-group legend {
  width: auto;
  display: inline-block;
  padding: 0 8px 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.add-Product-block .update-button-block {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-Product-block .update-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.submit-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.create-user-right-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.create-user-right-block .product-image-preview-box {
  width: 100%;
  padding: 0;
  margin: 0 0 20px 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.create-user-right-block .product-image-preview-box img {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  height: auto;
}
.submit-button-block .btn.cancel-button {
  background-color: #495057;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
}

.box-size {
  width: 55px;
}

.wallet_amount_style {
  border-radius: 5px;
  width: 43%;
  padding: 2px;
  margin: 8px 0;
  border: 2px solid #bdc9d5;
}
