.import-template-main-block {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.import-template-main-block .card {
  margin-bottom: 16px;
}

.import-template-main-block .card-body {
  padding: 1.25rem;
}

.select-import-template {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
}
.select-import-template .import-template-form {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  margin-right: 50px;
  max-width: 740px;
}

.select-import-template .import-template-form .form-group {
  margin-bottom: 0;
}

.import-template-main-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.import-template-main-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

/*////*/

.crm-contacts_block {
  width: 100%;
  padding: 0;
  margin: 0;
  outline: none;
  border: 1px solid #545b62;
}

.crm-contacts_block .nav-tabs {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.crm-contacts_block .nav-tabs li {
  display: inline-block;
  padding: 2px 1px;
  margin: 0;
  border: none;
  list-style: none;
}
.crm-contacts_block .nav-tabs li .nav-link {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 15px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0;
  box-shadow: none;
  background: #545b62;
  border: none;
  outline: none;
  font-size: 18px;
}

.crm-contacts_block .nav-tabs li .active.nav-link {
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
}

.crm-tabcontacts_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  margin: 0;
  border: none;
  outline: none;
}

.crm-tabcontacts_block .left-crm-contacts-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.crm-tabcontacts_block .drag-and-drop-block {
  width: 100%;
  padding: 0 0 0 15px;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-width: 40%;
  border-left: 1px solid #545b62;
}

.crm-tabcontacts_block .crm-contacts-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-height: 70vh;
  overflow: auto;
}
.crm-tabcontacts_block .drag-drop-all-items {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-height: calc(70vh - 70px);
  overflow: auto;
}

.crm-tabcontacts_block .drag-drop-all-items .drag-drop-item-list {
  width: 85%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.drag-drop-all-items .drag-drop-item-list li {
  width: 100%;
  padding: 4px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.contacts-list-item {
  width: 100%;
  padding: 4px;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contacts-list-item label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  max-width: 250px;
  margin-right: 10px;
}
.contacts-list-item .drag-drop-place-box {
  width: 100%;
  padding: 0;
  margin: 0 15px 0 0;
  border: none;
  outline: none;
  text-decoration: none;
  position: relative;
}
.crm-tabcontacts_block .drag-drop-item {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  text-align: center;
}
.contacts-list-item .drag-drop-place-box.active .drag-drop-item {
  background-color: #495057;
  border-color: #495057;
  color: #ffffff;
}

.contacts-list-item .drag-drop-place-box .drag-drop-item.active {
  background-color: #495057;
  border-color: #495057;
  color: #ffffff;
}

.contacts-list-item .close-button {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #495057;
  border: none;
  outline: none;
  color: #ffffff;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  font-size: 16px;
  transform: translateZ(0);
  font-weight: 300;
}
.contacts-list-item .close-button i {
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: normal;
}
.contacts-list-item .close-button:hover {
  text-decoration: none;
  background: #545cd8;
}

.crm-tabcontacts_block .drag-and-drop-block .titel {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 18px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  margin-right: 10px;
}
.crm-tabcontacts_block .drag-and-drop-block p {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 11px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
}

.drag-drop-all-items .drag-drop-item-list li .drag-drop-item {
  background-color: #495057;
  border-color: #495057;
  color: #ffffff;
  cursor: pointer;
}

.drag-drop-all-items .drag-drop-item-list li .drag-drop-item.inactive {
  background-color: #545cd8;
  border-color: #545cd8;
  color: #ffffff;
}

.contacts-button-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0 30px 0;
}
.contacts-button-block .button-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.crm-contacts-mainblock * {
  scrollbar-width: thin;
  scrollbar-color: #cad1d7 #ffffff;
}

.crm-contacts-mainblock ::-webkit-scrollbar {
  width: 12px;
}

.crm-contacts-mainblock ::-webkit-scrollbar-track {
  background: #ffffff;
}

.crm-contacts-mainblock ::-webkit-scrollbar-thumb {
  background-color: #cad1d7;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

/*//////notification-popup-block/////*/

.notification-modal .modal-content .modal-header {
  background-color: #eff3f6;
}

.notification-modal .modal-button-block {
  flex-wrap: wrap;
}
.notification-modal .modal-button-block button {
  margin: 8px;
}

.notification-popup-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.notification-popup-block .sub-titel {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.notification-popup-block .missing-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow: auto;
}
.notification-popup-block .missing-list li {
  display: flex;
  padding: 4px 8px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
.notification-popup-block .missing-list li::before {
  content: "\ea33";
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
  color: #990000;
}

.notification-popup-block .missing-list li:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.notification-popup-block .info-text {
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  background-color: #eff3f6;
  font-style: italic;
}

.notification-popup-block ul.list {
  width: 100%;
  padding: 0;
  margin: 8px 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow: auto;
}
.notification-popup-block ul.list li {
  display: flex;
  padding: 8px 8px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
.notification-popup-block ul.list li::before {
  content: "\ea11";
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
}

.notification-popup-block ul.list li:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.notification-popup-block ul.list li b {
  display: inline-block;
  font-weight: bold;
  padding-right: 8px;
}

/*///////////create-order-main-block//////////*/

.create-order-main-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.create-order-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-bottom: 30px;
}
.create-order-block .form-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-bottom: 30px;
}
.header-title-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-bottom: 15px;
}
.header-title-block .card-title {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.header-title-block .card-subtitle {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #8898aa !important;
  font-weight: 600;
  line-height: normal;
}

.create-order-main-block .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.create-order-main-block .card-header {
  padding: 0.8rem 1.25rem;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.create-order-main-block .card-body {
  padding: 1.25rem;
}

.create-order-main-block .upload-map-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.create-order-main-block .upload-map-block h4 {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-order-main-block .upload-map-block p {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #8898aa !important;
  font-weight: 600;
  line-height: normal;
}
.create-order-main-block .upload-button-block {
  width: 100%;
  padding: 15px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.create-order-main-block .button-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-bottom: 30px;
}

.create-order-main-block .button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

/*/////////create-campaign-modal/////////*/

.modal-dialog.create-campaign-modal {
  max-width: 800px;
}

.create-campaign-modal .modal-title {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.create-campaign-modal .search-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.create-campaign-modal .search-box .form-group {
  margin-bottom: 0;
}

.create-campaign-modal .search-box .form-group label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-campaign-modal .search-box .form-group .form-control {
  padding-left: 40px !important;
}

.searchfile-list-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  list-style: none;
  padding-bottom: 30px;
}
.searchfile-list-block .searchfile-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 400px;
  overflow: auto;
}

.searchfile-list-block .searchfile-list li {
  display: inline-block;
  width: auto;
  padding: 4px 8px;
  margin: 0;
  border: none;
  outline: none;
}
.searchfile-list-block .searchfile-list .btn {
  padding: 4px !important;
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.searchfile-list-block .searchfile-list h5 {
  width: 100%;
  padding: 4px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.searchfile-list-block .searchfile-list i {
  font-size: 28px;
  color: #646262;
}

.create-campaign-modal .file-open-footer {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  list-style: none;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.create-campaign-modal .filename-block {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  padding-right: 30px;
}

.create-campaign-modal .button-block {
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  align-items: center;
}

.create-campaign-modal .button-block .btn {
  min-width: 90px;
  text-align: center;
}

.add-new-template-box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.add-new-template-box .btn {
  margin-left: 8px;
  font-size: 14px;
  padding: 12px 15px;
  white-space: nowrap;
  box-shadow: none;
}

.modal-dialog.cntacts-list-modal {
  max-width: 800px;
}

.modal-dialog.cntacts-list-modal .modal-content .modal-body {
  background-color: #f5f5f5 !important;
}

.modal-dialog.cntacts-list-modal .card-header {
  padding: 0.8rem 1.25rem;
  font-size: 16px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.modal-dialog.cntacts-list-modal .card-body {
  padding: 0;
}

.modal-dialog.cntacts-list-modal .cntacts-list-details .card {
  background-color: transparent;
  box-shadow: none;
}

.modal-dialog.cntacts-list-modal .cntacts-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow: auto;
}
.modal-dialog.cntacts-list-modal .cntacts-list li {
  display: flex;
  padding: 8px 8px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

.modal-dialog.cntacts-list-modal .cntacts-list li:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.modal-dialog.cntacts-list-modal .cntacts-list li b {
  display: inline-block;
  padding-right: 8px;
}

.orblock {
  width: 100%;
  display: inline-block;
  padding: 0 0 15px 0;
  margin: 0;
  font-size: 20px;
  color: #dc3545 !important;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}

.orblock span {
  display: inline-block;
  color: #dc3545 !important;
  font-weight: bold;
}

/*////////*/
.create-order-main-block .upload-mailing-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.create-order-main-block .upload-mailing-list .number-block {
  display: inline-block;
  padding: 0;
  margin: 0 8px 0 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.create-order-main-block .upload-mailing-list .number-block .number {
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #dc3545;
  font-weight: bold;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.create-order-main-block .form-item {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.create-order-main-block .form-item h4 {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-order-main-block .form-item p {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 10px;
  color: #8898aa !important;
  font-weight: 600;
  line-height: normal;
}

.create-order-main-block .form-item .info-text {
  width: 100%;
  padding: 8px 0 0 0;
  margin: 0;
  font-size: 11px;
  color: #495057 !important;
  font-weight: 600;
  line-height: normal;
  font-style: italic;
}

.create-order-main-block .form-item .form-group {
  margin-top: 8px;
}
.create-order-main-block .form-item .form-check {
  padding-left: 0;
  margin: 0;
}
.create-order-main-block .form-item .form-check-input {
  float: left;
}
.create-order-main-block .form-item .form-check-label {
  font-size: 11px;
  color: #8898aa !important;
}

/*/////add-mailing-list-modal/////*/

.add-mailing-list-modal .modal-body {
  background-color: #fafbfc !important;
}
.add-mailing-list-modal .add-mailing-list-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.add-mailing-list-modal .add-mailing-list-box .form-group {
  margin-bottom: 1.5rem;
}
.add-mailing-list-modal .add-mailing-list-box .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.searchfile-list-block .list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  max-height: 300px;
  overflow: auto;
}
.searchfile-list-block .list li {
  display: flex;
  padding: 6px 8px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}
.searchfile-list-block .list li strong {
  display: inline-block;
  padding-right: 4px;
}

.searchfile-list-block .list li::before {
  content: "\ea11";
  display: inline-block;
  font: normal normal normal 14px/1 NucleoIcons;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
}

.searchfile-list-block .list li:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.searchfile-list-block .list li.gray-bg {
  background-color: #545b62 !important;
  color: #ffffff !important;
}

.searchfile-list-block .list li.gray-bg::before {
  display: none !important;
}

.order-details-box .searchfile-list-block .list {
  max-height: inherit;
}

/*////////Create Order//////*/

.create-order-main-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.Campaign-table-details,
.order-approval-list-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.create-order-main-block .card {
  margin-bottom: 30px;
}
.create-order-main-block .card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #fafbfc;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-order-main-block .card .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.create-order-main-block .card .card-header-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: #fafbfc;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.create-order-main-block .Campaign-table-details .btn {
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 2px;
  margin: 0;
  box-shadow: none;
  color: #5e72e4;
  text-align: right;
}

.create-order-main-block .card-header .btn {
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 2px;
  margin: 0;
  box-shadow: none;
  color: #5e72e4;
}

.order-approval-details-block .order-approval-list-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.order-approval-details-block .add-all-items-usercart {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.order-approval-details-block .add-all-items-usercart .form-group {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.order-approval-details-block .order-approval-list-block .row {
  margin-right: -4px;
  margin-left: -4px;
}
.order-approval-details-block .order-approval-list-block .col-sm-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.order-approval-details-block .usercart-items-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
}
.order-approval-details-block .usercart-items-box .card-header {
  background-color: #ebbc1b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.order-approval-details-block .usercart-items-box .usercart-items-header-block {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #ebbc1b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.order-approval-details-block
  .usercart-items-box
  .usercart-items-header-block
  .btn {
  background-color: transparent !important;
  color: #ffffff;
  padding: 4px 8px;
  font-size: 18px;
}

.order-approval-details-block .usercart-items-box.miling-list-box .card-header {
  background-color: #ed1c24;
}

.order-approval-details-block
  .usercart-items-box.miling-list-box
  .usercart-items-header-block {
  background-color: #ed1c24;
}

.order-approval-details-block .usercart-items-box .card-body {
  padding: 8px;
}
.order-approval-details-block .usercart-items-box ul {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  list-style: none;
}

.order-approval-details-block .usercart-items-box .mailing-date {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.order-approval-details-block .usercart-items-box .drop-block {
  width: 100%;
  padding: 8px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.order-approval-details-block .usercart-items-box .drop-block .template-image {
  display: inline-block;
  width: 72px;
  min-width: 72px;
  height: 94px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-content: center;
}
.order-approval-details-block
  .usercart-items-box
  .drop-block
  .drop-content-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  margin-left: 8px;
}

.order-approval-details-block .usercart-items-box .drop-block .titel1 {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  text-align: left;
}
.order-approval-details-block .usercart-items-box .drop-block .product-name {
  width: 100%;
  padding: 0 0 4px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 600;
  text-align: left;
  color: #ebbc1b;
}
.order-approval-details-block .usercart-items-box .product-short-details {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
}
.order-approval-details-block .usercart-items-box .product-price {
  width: 100%;
  padding: 8px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: bold;
  text-align: left;
}

.order-approval-details-block .usercart-items-box .product-details-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
}
.order-approval-details-block .usercart-items-box .product-details-block ul {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  list-style: none;
}

.order-approval-details-block .usercart-items-box .product-details-block ul li {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  list-style: none;
}
.order-approval-details-block
  .usercart-items-box
  .product-details-block
  ul
  li
  strong {
  font-weight: bold;
  padding-right: 8px;
}

.usercart-items-box .items-button-block {
  width: 100%;
  padding: 16px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usercart-items-box .items-button-block .download-proof-btn {
  display: inline-block;
  padding: 5px;
  background-color: #ed1c24 !important;
  font-size: 9px;
  color: #ffffff !important;
  margin: 0;
  border: none !important;
  border-radius: 0;
}
.usercart-items-box .items-button-block .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 9px;
}
.usercart-items-box .items-button-block .form-group .form-check-input {
  display: inline-block;
  padding: 0;
  margin: 0 4px 0 0 !important;
}
.usercart-items-box .items-button-block .form-group label {
  font-size: 10px;
  font-weight: 600;
  display: inline-block;
  padding: 0;
  margin: 0;
}

/*///////choose-design//////*/

.modal-dialog.add-product-to-order-modal {
  width: 100%;
  max-width: 1100px;
}

.choose-design-main-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.choose-design-main-block .titel-block {
  width: 100%;
  padding: 8px 0 16px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.choose-design-main-block .titel-block .titel {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  font-weight: bolder;
  text-decoration: #000000;
  text-transform: uppercase;
  font-size: 22px;
}

.choose-design-main-block .choose-design-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.choose-design-main-block .choose-design-block .left-bar-main {
  display: inline-block;
  width: 100%;
  max-width: 185px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.choose-design-main-block .choose-design-block .products-contener-main {
  display: inline-block;
  width: 100%;
  padding: 0 30px 0 30px;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.choose-design-main-block .choose-design-block .choose-all-products-box-main {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-height: 90vh;
  overflow: auto;
}
.choose-design-main-block .choose-design-block .leftbar-titel {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  color: #ed1c24;
  position: relative;
  font-weight: bolder;
  font-size: 22px;
  padding-bottom: 10px;
  line-height: normal;
}
.choose-design-main-block .choose-design-block .leftbar-titel::after {
  content: "";
  width: 100%;
  background-color: #ed1c24;
  max-width: 80%;
  position: absolute;
  height: 2px;
  left: 10%;
  bottom: 0;
}

.choose-design-main-block .search-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.choose-design-main-block .search-box .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.choose-design-main-block .search-box .form-group .form-control {
  border: none;
  outline: none;
  box-shadow: none !important;
  padding: 4px;
  margin: 0;
  font-size: 16px;
}
.choose-design-main-block .search-box .form-group label {
  border: none;
  outline: none;
  box-shadow: none !important;
  padding: 4px;
  margin: 0;
  font-size: 16px;
}

.choose-design-main-block .clear-all-selections {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.choose-design-main-block .clear-all-selections .btn {
  display: inline-block;
  padding: 0 !important;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  background: none !important;
  font-size: 14px;
  color: #5e72e4 !important;
  box-shadow: none !important;
}

.choose-design-main-block .sortby-all-listbox {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-height: 80vh;
  overflow: auto;
}

.choose-design-main-block .selections-listbox {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 8px 0 16px 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.choose-design-main-block .selections-listbox .listbox-titel {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  text-decoration: #707070;
  text-transform: uppercase;
  font-size: 16px;
  padding-bottom: 8px;
}
.choose-design-main-block .selections-listbox .lselections-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  text-decoration: #707070;
  font-size: 14px;
  list-style: none;
}
.choose-design-main-block .selections-listbox .lselections-list li {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  text-decoration: #707070;
  font-size: 14px;
  list-style: none;
}

.choose-design-main-block .selections-listbox .list-checkbox {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  text-decoration: #707070;
  font-size: 14px;
  list-style: none;
}

.choose-design-main-block .selections-listbox .list-checkbox .form-group {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.choose-design-main-block
  .selections-listbox
  .list-checkbox
  .form-group
  .form-check-input {
  width: 14px;
  height: 14px;
  padding: 0;
  margin: 3px ​8px 0 0 !important;
  margin-top: 3px !important;
}
.choose-design-main-block
  .selections-listbox
  .list-checkbox
  .form-group
  .form-check-label {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  text-decoration: #707070;
  font-size: 14px;
  text-transform: none;
  font-weight: 600;
}

.choose-design-main-block .choose-products-list-block {
  width: 100%;
  padding: 20px 0 10px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.choose-design-main-block .choose-products-list-block .header-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  color: #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.choose-design-main-block .choose-products-list-block .header-block .titel {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  color: #b5b5b5;
  text-decoration: none;
  text-transform: none;
  font-size: 20px;
}
.choose-design-main-block .choose-products-list-block .header-block .sub-titel {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  color: #484848;
  text-decoration: none;
  text-transform: none;
  font-size: 16px;
}

.choose-design-main-block .choose-products-list-block .sub-titel {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  color: #484848;
  text-decoration: none;
  text-transform: none;
  font-size: 18px;
}

.choose-design-main-block
  .choose-products-list-block
  .header-block
  .see-all-button {
  display: inline-block;
  padding: 2px 16px !important;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  background: none !important;
  font-size: 14px;
  color: #5e72e4 !important;
  box-shadow: none !important;
}

.choose-design-main-block
  .choose-products-list-block
  .header-block
  .header-left-block {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.choose-design-main-block
  .choose-products-list-block
  .header-block
  .header-right-block {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.choose-design-main-block .choose-products-list-block .products-list-block {
  width: 100%;
  padding: 15px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.choose-design-main-block
  .choose-products-list-block
  .products-list-block
  .products-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.choose-design-main-block .products-list-block .products-list li {
  width: 100%;
  max-width: 95px;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  margin-right: 15px;
}
.choose-design-main-block .products-list-block .products-list a {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
}
.choose-design-main-block .products-list-block .products-list a:hover {
  text-decoration: none;
  opacity: 0.8;
}
.choose-design-main-block .products-list-block .products-list .products-box {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.choose-design-main-block
  .products-list-block
  .products-list
  .products-box
  .product-image {
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.choose-design-main-block
  .new-products
  .products-list-block
  .products-list
  .products-box
  .product-image::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  padding: 0;
  margin: 0;
  background: url(../img/new-arrow.png) no-repeat;
  position: absolute;
  top: 0;
  left: 2px;
  z-index: 99;
}
.choose-design-main-block
  .most-popular
  .products-list-block
  .products-list
  .products-box
  .product-image::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  padding: 0;
  margin: 0;
  background: url(../img/most-popular-arrow.png) no-repeat;
  position: absolute;
  top: 0;
  left: 2px;
  z-index: 99;
}

.choose-design-main-block
  .products-list-block
  .products-list
  .products-box
  .product-image
  img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.choose-design-main-block
  .products-list-block
  .products-list
  .products-box
  .product-details {
  width: 100%;
  display: inline-block;
  padding: 8px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.choose-design-main-block .products-box .product-details .titel {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  text-decoration: none;
  color: #edbd1c;
  font-size: 13px;
  text-transform: none;
  font-weight: 600;
}

.choose-design-main-block .products-box .product-details p {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  text-decoration: none;
  color: #000;
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
}
.choose-design-main-block .red-color-text {
  color: #ef363d !important;
}
.choose-design-main-block .yellow-color-text {
  color: #edbd1c !important;
}
.choose-design-main-block .blue-color-text {
  color: #0072b9 !important;
}

.choose-design-main-block .text-deactive {
  color: #cccccc !important;
}

.choose-design-main-block * {
  scrollbar-width: thin;
  scrollbar-color: #cad1d7 #ffffff;
}

.choose-design-main-block ::-webkit-scrollbar {
  width: 12px;
}

.choose-design-main-block ::-webkit-scrollbar-track {
  background: #ffffff;
}

.choose-design-main-block ::-webkit-scrollbar-thumb {
  background-color: #cad1d7;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

/*///////choose-design//////end/////*/

.show-product-details-modal {
  max-width: 740px;
}

.add-product-details-popup {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.add-product-details-popup .header-block {
  width: 100%;
  padding: 8px 0 16px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
}

.add-product-details-popup .header-block .titel {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: center;
  font-weight: bolder;
  text-decoration: #000000;
  text-transform: uppercase;
  font-size: 22px;
}

.add-product-details-popup .product-left-details-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.add-product-details-popup .product-image {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.add-product-details-popup .product-left-details-block .text-block {
  width: 100%;
  padding: 0 0 20px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  list-style: none;
}

.add-product-details-popup .product-left-details-block .text-block .sub-titel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  background-color: #fafbfc;
  font-size: 18px;
  color: #495057;
  font-weight: bold;
  line-height: normal;
}

.add-product-details-popup .product-left-details-block .text-block .sub-titel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  margin: 0 0 10px 0;
  background-color: #fafbfc;
  font-size: 18px;
  color: #495057;
  font-weight: bold;
  line-height: normal;
}

.add-product-details-popup .product-left-details-block .text-block p {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  list-style: none;
}

.add-product-details-popup .product-image {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.add-product-details-popup .product-image img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

/*///////*/

.import-template-main-block .select-import-template .next-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  margin-top: 25px;
}
.import-template-main-block main {
  padding: 0 !important;
  padding-top: 0 !important;
}
/*////new///*/

.Campaign-table-details .tabile-main-block {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: auto;
  border: none;
  outline: none;
}

/*////////orderlist-details-block/////*/

.orderlist-details-block {
  width: 100%;
  padding: 0 15px;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-width: 100%;
  background-color: #fbfbfb;
}

.orderlist-details-block .orderlist-item-box .list-item.product-name {
  min-width: 120px;
}

.orderlist-details-block .orderlist-item-box .list-item.desired-mail-date {
  min-width: 120px;
}

.orderlist-details-block .orderlist-item-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 8px 0;
  outline: none;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  font-size: 12px;
  color: #7f7f7f;
}
.orderlist-details-block .orderlist-item-box .list-item {
  display: flex;
  padding: 0 8px;
  margin: 0;
  border: none;
  outline: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-width: 100px;
}

.orderlist-details-block .orderlist-item-box .list-item .item-titel {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: #edbd1c;
}
.orderlist-details-block .orderlist-item-box .list-item p {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: #7f7f7f;
}

.orderlist-details-block
  .orderlist-item-box
  .list-item.product-name
  .item-titel {
  color: #7f7f7f;
  font-size: 14px;
}

.orderlist-details-block .orderlist-item-box .list-item.product-name p {
  color: #edbd1c;
  font-size: 14px;
}

.orderlist-details-block .orderlist-item-box .deactive {
  cursor: none;
  opacity: 0.2 !important;
}

.orderlist-details-block
  .orderlist-item-box
  .list-item.total-piece
  .item-titel {
  font-weight: bold;
  font-size: 14px;
}

.orderlist-details-block .orderlist-item-box .list-item.total-piece p {
  font-weight: bold;
  font-size: 14px;
}

.choose-products-list-block .header-right-block {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: start;
  padding: 0;
  margin: 0;
}
.choose-products-list-block .header-right-block .see-all-button {
  display: inline-block;
  padding: 8px 16px !important;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  background: none !important;
  font-size: 14px;
  color: #5e72e4 !important;
  box-shadow: none !important;
}

#see_all_product_sections {
  width: 100%;
  padding: 0;
  margin: 0;
}

.choose-design-main-block .choose-design-block #all_product_sections,
.choose-design-main-block .choose-design-block #see_all_product_sections {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

/*/////new/////*/

.order-products-listblock {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  padding-bottom: 30px;
}
.order-products-listblock .products-list-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.order-products-listblock .products-list-block .products-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  flex-wrap: wrap;
}

.order-products-listblock .products-list-block .products-list li {
  width: 100%;
  max-width: 95px;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  margin-right: 15px;
}
.order-products-listblock .products-list-block .products-list a {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
}
.order-products-listblock .products-list-block .products-list a:hover {
  text-decoration: none;
  opacity: 0.8;
}
.order-products-listblock .products-list-block .products-list .products-box {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  position: relative;
}

.order-products-listblock
  .products-list-block
  .products-list
  .products-box
  .product-image {
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-height: 140px;
}
.order-products-listblock
  .products-list-block
  .new-products
  .products-box
  .product-image::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  padding: 0;
  margin: 0;
  background: url(../img/new-arrow.png) no-repeat;
  position: absolute;
  top: 0;
  left: 2px;
  z-index: 99;
}
.order-products-listblock
  .products-list-block
  .most-popular
  .products-box
  .product-image::before {
  content: "";
  width: 22px;
  height: 22px;
  display: inline-block;
  padding: 0;
  margin: 0;
  background: url(../img/most-popular-arrow.png) no-repeat;
  position: absolute;
  top: 0;
  left: 2px;
  z-index: 99;
}

.order-products-listblock
  .products-list-block
  .products-list
  .products-box
  .product-image
  img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.order-products-listblock .products-list-block .removed-item {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  color: #ffffff;
  z-index: 99;
  transition: all 0.2s;
  display: none;
  opacity: 0;
}
.order-products-listblock .products-list-block .removed-item .delete-button {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: inherit;
  transition: all 0.2s;
  background-color: #dc3545;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
}
.order-products-listblock .products-list-block .removed-item i {
  display: inline-block;
  font-size: 18px;
  color: #ffffff;
}

.order-products-listblock
  .products-list-block
  .products-box:hover
  .removed-item {
  display: flex;
  opacity: 1;
}
.order-products-listblock
  .products-list-block
  .products-list
  .products-box
  .product-details {
  width: 100%;
  display: inline-block;
  padding: 8px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}

.order-products-listblock .products-box .product-details .titel {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  text-decoration: none;
  color: #edbd1c;
  font-size: 13px;
  text-transform: none;
  font-weight: 600;
}

.order-products-listblock .products-box .product-details p {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  text-decoration: none;
  color: #000;
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
}
.order-products-listblock .red-color-text {
  color: #ef363d !important;
}
.order-products-listblock .yellow-color-text {
  color: #edbd1c !important;
}
.order-products-listblock .blue-color-text {
  color: #0072b9 !important;
}

.order-products-listblock .text-deactive {
  color: #cccccc !important;
}
