/*////Profile////*/

.profile-information-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.profile-information-block .card {
  margin-bottom: 30px;
}
.profile-information-block .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #fafbfc;
}
.profile-information-block .card-header .form-check {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 12px;
}
.profile-information-block .card-header .form-check input[type="radio"],
input[type="checkbox"] {
  position: static;
  width: 18px;
  height: 18px;
}

.profile-information-block .card-body {
  padding: 1.25rem;
}

.profile-information-block .form-group {
  margin-bottom: 8px;
}

.profile-information-block .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.profile-information-block .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.profile-information-block .update-button-block {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profile-information-block .update-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.submit-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.print-profile-right-block {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: right;
}

.profile-right-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.profile-right-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.profile-right-box .user-profile-box .card {
  background-color: #545cd8 !important;
  text-align: center;
}
.profile-right-box .user-profile-box .user-image {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: #f1f1f1;
  overflow: hidden;
  border: 4px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem auto 0 auto;
}
.profile-right-box .user-profile-box .user-image img {
  max-width: 100%;
  min-height: 100%;
  min-height: 100%;
}

.profile-right-box .user-name {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  line-height: normal;
}
.profile-right-box .user-name .card-title {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  line-height: normal;
}
.profile-right-box .upload-profile-photo-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  margin-top: 1.25rem;
  position: relative;
}
.profile-right-box .upload-profile-photo-block .form-group {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  position: relative;
}
.profile-right-box .upload-profile-photo-block label {
  display: inline-block;
  font-weight: 600;
  color: #111111;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f7b924;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  width: 100%;
  cursor: pointer;
}

.profile-right-box .upload-profile-photo-block label:hover {
  color: #111111;
  background-color: #eca909;
  border-color: #eca909;
  box-shadow: 0 0.125rem 0.625rem rgb(52 58 64 / 40%),
    0 0.0625rem 0.125rem rgb(52 58 64 / 50%);
}
.profile-right-box .upload-profile-photo-block .custom-file {
  position: static;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  box-shadow: none !important;
  height: auto;
}
.profile-right-box .upload-profile-photo-block .custom-file .custom-file-label {
  width: 100%;
  position: static;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  background: none !important;
  font-size: 12px;
  text-align: center;
  box-shadow: none !important;
  display: none !important;
}
.profile-right-box .upload-profile-photo-block .custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  box-shadow: none !important;
}
.profile-right-box
  .upload-profile-photo-block
  .custom-file
  .custom-file-label:after {
  display: none !important;
}

.profile-information-block .profile-right-box .card-header {
  background: none;
}

.profile-right-box .table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.profile-right-box .table thead th {
  border-bottom: 1px solid #e9ecef;
  font-size: 14px;
  font-weight: bold;
}

.profile-right-box .table thead {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e9ecef;
  padding: 0.55rem;
  line-height: normal;
  position: relative;
  font-weight: 700;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  color: #545cd8;
}

.profile-right-box.table tbody tr {
  border-bottom: 1px solid #e9ecef;
}

.profile-right-box .table tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.profile-right-box .table td {
  border-right: 1px solid #e9ecef;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #495057;
  text-align: left;
  word-wrap: break-word;
  padding: 8px 15px !important;
  vertical-align: middle;
  vertical-align: middle;
}
.profile-right-box .table td:last-child {
  text-align: right;
}
.profile-right-box .table th {
  font-weight: bold;
  padding: 5px 15px !important;
  vertical-align: middle;
}

.profile-right-box .table .dropdown .btn {
  display: inline-block;
  padding: 2px 8px;
  margin: 0;
  border: none !important;
  outline: none;
  text-decoration: none;
  color: #495057;
  box-shadow: none;
  background-color: transparent !important;
}

.profile-right-box .card-footer {
  background-color: #ffffff;
  padding: 0 0 1.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.profile-right-box .order-view-button-block {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-right-box .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  height: 35px;
  background: #545cd8;
  border: none;
  outline: none;
  box-shadow: none;
}

.profile-right-box .add-drop-button-block .btn i {
  font-size: 18px;
  padding-right: 4px;
}

.profile-right-box .btn.btn-secondary {
  background-color: #495057;
}

.profile-right-box .btn.btn-success {
  color: #fff;
  background-color: #3ac47d;
  border-color: #3ac47d;
  box-shadow: none;
}

.profile-right-box .btn.btn-success:hover {
  color: #fff;
  background-color: #31a66a;
  border-color: #2e9d64;
  box-shadow: 0 0.125rem 0.625rem rgb(52 58 64 / 40%),
    0 0.0625rem 0.125rem rgb(52 58 64 / 50%);
}

.profile-right-box .wallet-balance {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #545cd8;
  font-weight: 600;
  line-height: normal;
}
.profile-right-box .wallet-balance .card-title {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: #545cd8;
  font-weight: bold;
  line-height: normal;
}

/* 03-09-2021 */

.listhilight-block {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.listhilight-block .card {
  margin-bottom: 16px;
  box-shadow: none;
}

.listhilight-block .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: #fafbfc;
  font-size: 16px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.listhilight-block .card-body {
  padding: 8px 16px;
}

.listhilight-block .listhilight-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
}
.listhilight-block .listhilight-list li {
  width: 100%;
  padding: 5px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.listhilight-block .listhilight-list li:nth-child(even) {
  background: #fafbfc;
}

.listhilight-block .listhilight-list li label {
  width: auto;
  min-width: 120px;
  padding: 0 8px 0 8px;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.listhilight-block .listhilight-list li span {
  width: auto;
  min-width: 120px;
  padding: 8px 16px;
  margin: 0;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  line-height: normal;
  background-color: #545cd887;
  letter-spacing: 1px;
  border-radius: 4px;
}

.crop-image-modal-profile .crop-image-btn-block-profile {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0 0 0;
  margin: 0;
}

.crop-image-modal-profile .ReactCrop {
  width: 100%;
}
.crop-image-modal-profile .ReactCrop .ReactCrop__image {
  width: 100%;
}

.logo-control-main-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
  margin: 0;
  border: none;
  outline: none;
}

.logo-control-main-block .logo-control-box {
  display: inline-block;
  max-width: 48%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  width: 45%;
}

.logo-control-main-block .logo-control-box .logo-adjust-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
}

.image-crop-body p{
  width: 100%;
  padding: 0 0 20px 0;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
}

.logo-control-main-block .logo-control-box input{
  width: 100%;
}

.logo-control-main-block .logo-control-box input[type=range] {
  height: 20px;
  -webkit-appearance: none;
  margin: 5px 0;
  width: 100%;
}
.logo-control-main-block .logo-control-box input[type=range]:focus {
  outline: none;
}
.logo-control-main-block .logo-control-box input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #525f7f;
  border-radius: 5px;
  border: 1px solid #000000;
}
.logo-control-main-block .logo-control-box input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 15px;
  width: 8px;
  border-radius: 5px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
.logo-control-main-block .logo-control-box input[type=range]:focus::-webkit-slider-runnable-track {
  background: #525f7f;
}
.logo-control-main-block .logo-control-box input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #525f7f;
  border-radius: 5px;
  border: 1px solid #000000;
}
.logo-control-main-block .logo-control-box input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 15px;
  width: 8px;
  border-radius: 5px;
  background: #FFFFFF;
  cursor: pointer;
}
.logo-control-main-block .logo-control-box input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.logo-control-main-block .logo-control-box input[type=range]::-ms-fill-lower {
  background: #525f7f;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
.logo-control-main-block .logo-control-box input[type=range]::-ms-fill-upper {
  background: #525f7f;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
.logo-control-main-block .logo-control-box input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 15px;
  width: 8px;
  border-radius: 5px;
  background: #FFFFFF;
  cursor: pointer;
}
.logo-control-main-block .logo-control-box input[type=range]:focus::-ms-fill-lower {
  background: #525f7f;
}
.logo-control-main-block .logo-control-box input[type=range]:focus::-ms-fill-upper {
  background: #525f7f;
}

.modal-dialog.crop-image-modal-profile {
  max-width: 680px;
}