.list-filtered-table-block .right-button-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.list-filtered-table-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 8px 16px;
  margin: 4px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  background: #545cd8 !important;
  border: none;
  outline: none;
  font-size: 14px;
}
.list-filtered-table-block .btn i {
  display: inline-block;
  padding-right: 8px;
}
.list-filtered-table-block .rdt_TableHeader .fgINsU {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.list-filtered-table-block header {
  padding: 0;
  margin: 0;
  border: none;
}
.list-filtered-table-block .form-group {
  padding: 0;
  margin: 0;
  width: 100%;
}
.list-filtered-table-block .form-group input {
  box-shadow: none !important;
  background-color: #ffffff;
}
/*//////tabile//////*/

.list-filtered-table-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: #ffffff;
  overflow: hidden;
}

.list-filtered-table-block .fzmYSh {
  background-color: transparent;
}

.list-filtered-table-block .rdt_TableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #ffffff;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  overflow: visible;
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  min-height: 20px !important;
}

.rdt_Table .rdt_TableRow {
  min-height: 38px !important;
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow div:last-child,
.rdt_Table .rdt_TableRow div:last-child {
  justify-content: flex-end;
}
.add-subscription-block .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.rdt_Table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.rdt_Table .rdt_TableHead {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e9ecef;
  padding: 4px;
  line-height: normal;
  position: relative;
  font-weight: 700;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  color: #545cd8;
}
.rdt_Table .rdt_TableHead .rdt_TableHeadRow {
  background-color: transparent;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.rdt_Table .rdt_TableHead .rdt_TableCol {
  border-bottom: 1px solid #e9ecef;
  font-size: 14px;
  font-weight: bold;
  color: #545cd8;
  line-height: normal;
}

.rdt_Table .rdt_TableHead .cJjDWM {
  color: #545cd8;
}

.rdt_Table .dropdown .btn {
  display: inline-block;
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  background: transparent !important;
  border: none;
  box-shadow: none;
  color: #212529 !important;
  float: right;
}

.list-filtered-table-block .rdt_TableHeader .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  height: 35px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #495057;
}

.rdt_Table .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

/*//////popup///////*/

.popup-form-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: #ffffff;
}

.popup-form-block .modal-content {
  margin-bottom: 30px;
}
.popup-form-block .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #ffffff;
}

.modal-content .modal-header .modal-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.modal-content .modal-body {
  padding: 1.25rem;
  background-color: #ffffff;
}

.popup-form-block .form-group {
  margin-bottom: 8px;
}

.popup-form-block .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.popup-form-block .popup-button-block {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.popup-form-block .popup-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 8px 20px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: none;
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 16px;
}

.popup-form-block .popup-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.popup-form-block .radio-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px 0 0 0;
  margin: 0;
  border: none;
}
.popup-form-block .radio-block legend {
  width: auto;
  display: inline-block;
  padding: 0 15px 0 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.popup-form-block .radio-block .form-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  border: none;
}
.popup-form-block .radio-block .form-check {
  margin-right: 10px;
}
.popup-form-block .radio-block .form-group legend {
  width: auto;
  display: inline-block;
  padding: 0 8px 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.rdt_Table .select-role .form-control {
  font-size: 14px;
  padding: 4px 8px;
  height: auto;
  margin: 8px 0;
  width: 100%;
}
.rdt_Table .switch-button {
  position: relative;
}
.rdt_Table .switch-button .custom-switch {
  padding-left: 0;
  position: relative;
  width: auto;
  max-width: 50px;
}
.rdt_Table .switch-button .custom-switch .custom-control-label::before {
  left: 0;
}
.rdt_Table .switch-button .custom-switch .custom-control-label::after {
  left: calc(0px + 2px);
}
.rdt_Table .switch-button .custom-control-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.user-roll-block .rdt_Table .rdt_TableCol:nth-child(4),
.user-roll-block .rdt_Table .rdt_TableCell:nth-child(4) {
  max-width: 90px;
}

.user-roll-block .rdt_Table .rdt_TableCol:nth-child(5),
.user-roll-block .rdt_Table .rdt_TableCell:nth-child(5) {
  max-width: 90px;
}

/*/////order/////*/

.rdt_Table .status-round {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: #ccc;
}
.rdt_Table .status-round.all-jobs {
  background-color: #1cee31;
  border-color: #1cee31;
}
.rdt_Table .status-round.on-hold {
  background-color: #fbf200;
  border-color: #fbf200;
}
.rdt_Table .status-round.ready-to-post {
  background-color: #1cadee;
  border-color: #1cadee;
}

.order-tabile-block .rdt_Table .rdt_TableCell:first-child,
.order-tabile-block .rdt_Table .rdt_TableCol:first-child {
  max-width: 20px;
  min-width: 10px;
  padding: 0;
  margin: 0 0 0 10px;
}
.order-tabile-block .rdt_Table .rdt_TableCell:last-child,
.order-tabile-block .rdt_Table .rdt_TableCol:last-child {
  max-width: 90px;
  min-width: 10px;
  padding: 0;
  margin: 0 0 0 10px;
}

.order-tabile-block .rdt_Table .domoiu {
  min-width: 150px;
}

.order-tabile-block .rdt_Table .domoiu:nth-child(3) {
  min-width: 250px;
}

.order-tabile-block .rdt_Table .domoiu:nth-child(14) {
  min-width: 250px;
}

.order-tabile-block .rdt_Table .domoiu:nth-child(15) {
  min-width: 250px;
}

.order-tabile-block .status-infoblock {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.order-tabile-block .statusinfo-box {
  width: auto;
  padding: 0 15px 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
}

.order-tabile-block .statusinfo-box .status-round {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
  padding: 0;
  margin: 0 4px 0 0;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: #ccc;
}
.order-tabile-block .statusinfo-box .status-round.all-jobs {
  background-color: #1cee31;
  border-color: #1cee31;
}
.order-tabile-block .statusinfo-box .status-round.on-hold {
  background-color: #fbf200;
  border-color: #fbf200;
}
.order-tabile-block .statusinfo-box .status-round.ready-to-post {
  background-color: #1cadee;
  border-color: #1cadee;
}
.list-filtered-table-block .rdt_Table .rdt_TableBody .rdt_TableRow {
  cursor: pointer;
}

.list-filtered-table-block .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
  background: #545cd8;
  color: #ffffff;
  text-decoration: none;
}
.select-user-box p {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 400;
  line-height: normal;
}
.select-user-box p b {
  font-weight: 600;
}

/*///////*/

.list-filtered-table-block .search_tablerow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  background-color: #ffffff;
  min-height: 56px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  flex-wrap: wrap;
}

.list-filtered-table-block .search_tablerow .rdt_TableCell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  flex-basis: 0;
  max-width: 100%;
  min-width: 100px;
  margin: 4px;
  flex-wrap: wrap;
}

.list-filtered-table-block .search_tablerow .rdt_TableCell label {
  width: 100%;
  padding: 4px 0 4px 0;
  margin: 0;
  font-size: 12px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.list-filtered-table-block .search_tablerow .rdt_TableCell .btn {
  height: 42px;
  width: 100%;
  margin: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.list-filtered-table-block header.hFVRPL {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 0;
}
.attribute-table-setting
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol {
  max-width: 290px;
}
.attribute-table-setting
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(5),
.attribute-table-setting
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(6) {
  max-width: 100px;
}
.attribute-table-setting
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell {
  max-width: 290px;
}
.attribute-table-setting
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-child(5),
.attribute-table-setting
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-child(6) {
  max-width: 100px;
}

.cms-data-table .rdt_Table .rdt_TableCol,
.cms-data-table .rdt_Table .rdt_TableCell {
  word-break: break-all;
  max-width: 300px;
}

.batch-preview-table .status-infoblock {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.batch-preview-table .statusinfo-box {
  width: auto;
  padding: 0 15px 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
}

.batch-preview-table .statusinfo-box .status-round {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
  padding: 0;
  margin: 0 4px 0 0;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: #ccc;
}
.batch-preview-table .statusinfo-box .status-round.all-jobs {
  background-color: #008000;
  border-color: #008000;
}
.batch-preview-table .statusinfo-box .status-round.on-hold {
  background-color: #ffa500;
  border-color: #ffa500;
}

.list-filtered-table-block .affiliate-search-list-block {
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 15px;
  border: none;
  justify-content: space-between;
}
.list-filtered-table-block
  .affiliate-search-list-block
  .affiliate-search-input {
  padding: 2px 0 2px 4px;
}
.list-filtered-table-block
  .affiliate-search-list-block
  .affiliate-search-input:first-child {
  padding-left: 4px 0 4px 8px;
}
.list-filtered-table-block
  .affiliate-search-list-block
  .affiliate-search-input
  .affiliate-search-btn {
  margin-top: 32px;
  height: 42px;
  min-width: 110px;
  text-align: center;
  justify-content: center;
}
.affiliate-data-table-block
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol,
.affiliate-data-table-block
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell {
  margin: 2px;
  min-width: 135px;
  text-indent: inherit !important;
  padding: 2px !important;
}

.affiliate-data-table-block
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(2),
.affiliate-data-table-block
  .rdt_Table
  .rdt_TableHead
  .rdt_TableHeadRow
  .rdt_TableCol:nth-child(3),
.affiliate-data-table-block
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-child(2),
.affiliate-data-table-block
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-child(3) {
  min-width: 280px !important;
  max-width: 300px;
  word-break: break-all !important;
  text-indent: inherit !important;
}

.affiliate-data-table-block .rdt_TableCell div:first-child {
  white-space: inherit;
  overflow: visible;
  text-overflow: inherit;
}

.list-filtered-table-block .search_tablerow .rdt_TableCell .contact-search-btn {
  margin-top: 20px;
}
