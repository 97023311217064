* {
  margin: 0;
  padding: 0;
  line-height: inherit;
  border: none;
  outline: none;
}

@font-face {
  font-family: "Impact";
  src: url(../fonts/Impact-Regular.eot); /* IE9*/
  src: url(../fonts/Impact-Regular.eot?) format("embedded-opentype"),
    url(../fonts/Impact-Regular.woff2) format("woff2"),
    url(../fonts/Impact-Regular.woff) format("woff"),
    url(../fonts/Impact-Regular.ttf) format("truetype"),
    url(../fonts/Impact-Regular.svg#Impact-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dotsfont";
  src: url(../fonts/dotsfont.eot);
  src: url(../fonts/dotsfont.eot?#iefix) format("embedded-opentype"),
    url(../fonts/dotsfont.woff) format("woff"),
    url(../fonts/dotsfont.ttf) format("truetype"),
    url(../fonts/dotsfont.svg#dotsfontregular) format("svg");
}

@font-face {
  font-family: "Impact";
  src: url(../fonts/Impact-Regular.eot); /* IE9*/
  src: url(../fonts/Impact-Regular.eot?) format("embedded-opentype"),
    url(../fonts/Impact-Regular.woff2) format("woff2"),
    url(../fonts/Impact-Regular.woff) format("woff"),
    url(../fonts/Impact-Regular.ttf) format("truetype"),
    url(../fonts/Impact-Regular.svg#Impact-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial Black";
  src: url(../fonts/Arial-Black.eot); /* IE9*/
  src: url(../fonts/Arial-Black.eot) format("embedded-opentype"),
    url(../fonts/Arial-Black.woff) format("woff"),
    url(../fonts/Arial-Black.ttf) format("truetype"),
    url(../fonts/Arial-Black.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gill Sans Ultra Bold Condensed";
  src: url(../fonts/GillSans-UltraBoldCondensed.eot);
  src: url(../fonts/GillSans-UltraBoldCondensed.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/GillSans-UltraBoldCondensed.woff2) format("woff2"),
    url(../fonts/GillSans-UltraBoldCondensed.woff) format("woff"),
    url(../fonts/GillSans-UltraBoldCondensed.ttf) format("truetype"),
    url(../fonts/GillSans-UltraBoldCondensed.svg#GillSans-UltraBoldCondensed)
      format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Swis721 Blk BT";
  src: url(../fonts/Swiss721BT-Black.eot);
  src: url(../fonts/Swiss721BT-Black.eot?#iefix) format("embedded-opentype"),
    url(../fonts/Swiss721BT-Black.woff2) format("woff2"),
    url(../fonts/Swiss721BT-Black.woff) format("woff"),
    url(../fonts/Swiss721BT-Black.ttf) format("truetype"),
    url(../fonts/Swiss721BT-Black.svg#Swiss721BT-Black) format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "brianberkawfont3";
  src: url(../fonts/brianberkawfont3.eot);
  src: url(../fonts/brianberkawfont3.eot?#iefix) format("embedded-opentype"),
    url(../fonts/brianberkawfont3.woff2) format("woff2"),
    url(../fonts/brianberkawfont3.woff) format("woff"),
    url(../fonts/brianberkawfont3.ttf) format("truetype"),
    url(../fonts/brianberkawfont3.svg#brianberkawfont3) format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "american-typewriter";
  src: url(../fonts/AmericanTypewriter.eot);
  src: url(../fonts/AmericanTypewriter.eot?#iefix) format("embedded-opentype"),
    url(../fonts/AmericanTypewriter.woff2) format("woff2"),
    url(../fonts/AmericanTypewriter.woff) format("woff"),
    url(../fonts/AmericanTypewriter.ttf) format("truetype"),
    url(../fonts/AmericanTypewriter.svg#AmericanTypewriter) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Rock Salt";
  src: url(../fonts/RockSalt.eot);
  src: url(../fonts/RockSalt.eot?#iefix) format("embedded-opentype"),
    url(../fonts/RockSalt.woff2) format("woff2"),
    url(../fonts/RockSalt.woff) format("woff"),
    url(../fonts/RockSalt.ttf) format("truetype"),
    url(../fonts/RockSalt.svg#RockSalt) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lithos Pro Black";
  src: url(../fonts/LithosPro-Black.eot);
  src: local("☺"), url(../fonts/LithosPro-Black.woff) format("woff"),
    url(../fonts/LithosPro-Black.ttf) format("truetype"),
    url(../fonts/LithosPro-Black.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue Medium";
  src: url(../fonts/HelveticaNeue-MediumCond_1.eot);
  src: local("☺"), url(../fonts/HelveticaNeue-MediumCond_1.woff) format("woff"),
    url(../fonts/HelveticaNeue-MediumCond_1.ttf) format("truetype"),
    url(../fonts/HelveticaNeue-MediumCond_1.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Trebuchet MS";
  src: url(../fonts/TrebuchetMS.eot);
  src: local("☺"), url(../fonts/TrebuchetMS.woff) format("woff"),
    url(../fonts/TrebuchetMS.ttf) format("truetype"),
    url(../fonts/TrebuchetMS.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tahoma Bold";
  src: url(../fonts/Tahoma-Bold.eot);
  src: local("☺"), url(../fonts/Tahoma-Bold.woff) format("woff"),
    url(../fonts/Tahoma-Bold.ttf) format("truetype"),
    url(../fonts/Tahoma-Bold.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cooper Black";
  src: url(../fonts/CooperBlack.eot);
  src: local("☺"), url(../fonts/CooperBlack.woff) format("woff"),
    url(../fonts/CooperBlack.ttf) format("truetype"),
    url(../fonts/CooperBlack.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times New Roman";
  src: url(../fonts/TimesNewRomanPSMT.eot); /* IE9*/
  src: url(../fonts/TimesNewRomanPSMT.eot) format("embedded-opentype"),
    url(../fonts/TimesNewRomanPSMT.woff) format("woff"),
    url(../fonts/TimesNewRomanPSMT.ttf) format("truetype"),
    url(../fonts/TimesNewRomanPSMT.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times";
  src: url(../fonts/Times.eot);
  src: url(../fonts/Times.eot?#iefix) format("embedded-opentype"),
    url(../fonts/Times.woff2) format("woff2"),
    url(../fonts/Times.woff) format("woff"),
    url(../fonts/Times.ttf) format("truetype"),
    url(../fonts/Times.svg) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tw Cen MT";
  src: url(../fonts/TwCenMT-Bold.eot); /* IE9*/
  src: url(../fonts/TwCenMT-Bold.eot) format("embedded-opentype"),
    url(../fonts/TwCenMT-Bold.woff) format("woff"),
    url(../fonts/TwCenMT-Bold.ttf) format("truetype"),
    url(../fonts/TwCenMT-Bold.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Hoffmanhand";
  src: url(../fonts/Hoffmanhand.eot); /* IE9*/
  src: url(../fonts/Hoffmanhand.eot) format("embedded-opentype"),
    url(../fonts/Hoffmanhand.woff) format("woff"),
    url(../fonts/Hoffmanhand.ttf) format("truetype"),
    url(../fonts/Hoffmanhand.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: url(../fonts/arial.woff);
  src: local("Arial"), url(../fonts/arial.woff) format("woff"),
    url(../fonts/arial.ttf) format("truetype");
}

@font-face {
  font-family: "Arial Bold";
  src: url(../fonts/Arial-BoldMT.eot);
  src: url(../fonts/Arial-BoldMT.eot?#iefix) format("embedded-opentype"),
    url(../fonts/Arial-BoldMT.woff2) format("woff2"),
    url(../fonts/Arial-BoldMT.woff) format("woff"),
    url(../fonts/Arial-BoldMT.ttf) format("truetype"),
    url(../fonts/Arial-BoldMT.svg#Arial-BoldMT) format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Hobo Std (OT1)";
  src: url(../fonts/HoboStd.eot);
  src: url(../fonts/HoboStd.eot?#iefix) format("embedded-opentype"),
    url(../fonts/HoboStd.woff2) format("woff2"),
    url(../fonts/HoboStd.woff) format("woff"),
    url(../fonts/HoboStd.ttf) format("truetype"),
    url(../fonts/HoboStd.svg#HoboStd) format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Own Written";
  src: url(../fonts/OwnWritten.eot);
  src: url(../fonts/OwnWritten.eot?#iefix) format("embedded-opentype"),
    url(../fonts/OwnWritten.woff2) format("woff2"),
    url(../fonts/OwnWritten.woff) format("woff"),
    url(../fonts/OwnWritten.ttf) format("truetype"),
    url(../fonts/OwnWritten.svg#OwnWritten) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "dotsfont";
  src: url(../fonts/dotsfont.eot);
  src: url(../fonts/dotsfont.eot?#iefix) format("embedded-opentype"),
    url(../fonts/dotsfont.woff) format("woff"),
    url(../fonts/dotsfont.ttf) format("truetype"),
    url(../fonts/dotsfont.svg#dotsfontregular) format("svg");
}

/* @font-face {
      font-family: 'kirbyshand';
      src: url(../fonts/KirbysHand_Regular.eot);
      src: url(../fonts/KirbysHand_Regular.eot?#iefix) format('embedded-opentype'),
           url(../fonts/KirbysHand_Regular.woff) format('woff'), 
           url(../fonts/KirbysHand_Regular.ttf) format('truetype'), 
           url(../fonts/KirbysHand_Regular.svg) format('svg');
      font-weight: normal;
      font-style: normal;
  }*/

@font-face {
  font-family: "kirbyshand";
  src: url(../fonts/KirbysHand_Regular.woff) format("woff"),
    url(../fonts/KirbysHand_Regular.ttf) format("truetype"),
    url(../fonts/KirbysHand_Regular.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Permanent Marker";
  src: url(../fonts/permanent_marker.eot);
  src: url(../fonts/permanent_marker.ttf) format("truetype"),
    url(../fonts/permanent_marker.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Times Bold";
  src: url(../fonts/times_bold.woff2) format("woff2"),
    url(../fonts/times_bold.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
      font-family: 'permanent_marker';
      src: url(../fonts/permanent_marker.eot);
      src: local('☺'), url(../fonts/permanent_marker.woff) format('woff'), 
      url(../fonts/permanent_marker.ttf) format('truetype'), 
      url(../fonts/permanent_marker.svg) format('svg');
      font-weight: normal;
      font-style: normal;
  }*/

@font-face {
  font-family: "Minion Pro";
  src: url(../fonts/MinionPro-Regular.eot);
  src: local("☺"), url(../fonts/MinionPro-Regular.woff) format("woff"),
    url(../fonts/MinionPro-Regular.otf) format("opentype"),
    url(../fonts/MinionPro-Regular.ttf) format("truetype"),
    url(../fonts/MinionPro-Regular.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "A Safe Place to Fall";
  src: url(../fonts/ASafePlacetoFall.eot);
  src: local("☺"), url(../fonts/ASafePlacetoFall.woff) format("woff"),
    url(../fonts/ASafePlacetoFall.ttf) format("truetype"),
    url(../fonts/ASafePlacetoFall.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Dakota";
  src: url(../fonts/Dakota.eot);
  src: local("☺"), url(../fonts/Dakota.woff) format("woff"),
    url(../fonts/Dakota.ttf) format("truetype"),
    url(../fonts/Dakota.svg) format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: "American Typewriter";
  src: url(../fonts/AmericanTypewriter-Condensed.eot?#iefix)
      format("embedded-opentype"),
    url(../fonts/AmericanTypewriter-Condensed.woff) format("woff"),
    url(../fonts/AmericanTypewriter-Condensed.ttf) format("truetype"),
    url(../fonts/AmericanTypewriter-Condensed.svg#AmericanTypewriter-Condensed)
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Playfair Display";
  src: url(../fonts/PlayfairDisplay-Regular.eot);
  src: local("☺"), url(../fonts/PlayfairDisplay-Regular.woff) format("woff"),
    url(../fonts/PlayfairDisplay-Regular.ttf) format("truetype"),
    url(../fonts/PlayfairDisplay-Regular.svg) format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: "PlayfairDisplay-Bold";
  src: url(../fonts/PlayfairDisplay-Bold.eot);
  src: local("☺"), url(../fonts/PlayfairDisplay-Bold.woff) format("woff"),
    url(../fonts/PlayfairDisplay-Bold.ttf) format("truetype"),
    url(../fonts/PlayfairDisplay-Bold.svg) format("svg");
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
}

@font-face {
  font-family: "Danzo";
  src: url(../fonts/Danzo.eot);
  src: url(../fonts/Danzo.eot?#iefix) format("embedded-opentype"),
    url(../fonts/Danzo.woff2) format("woff2"),
    url(../fonts/Danzo.woff) format("woff"),
    url(../fonts/Danzo.ttf) format("truetype"),
    url(../fonts/Danzo.svg#Danzo) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gill Sans Std";
  src: url(../fonts/GillSansStd.eot);
  src: local("☺"), url(../fonts/GillSansStd.woff) format("woff"),
    url(../fonts/GillSansStd.ttf) format("truetype"),
    url(../fonts/GillSansStd.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

/*////new-fonts////*/

@font-face {
  font-family: "IDAutomation2D";
  src: url(../fonts/IDAutomation2D.otf);
  src: url(../fonts/IDAutomation2D.woff2) format("woff2"),
    url(../fonts/IDAutomation2D.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "USPSIMBStandard";
  src: url(../fonts/USPSIMBStandard.woff) format("woff"),
    url(../fonts/USPSIMBStandard.ttf) format("truetype"),
    url(../fonts/USPSIMBStandard.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArialMT";
  src: url("../fonts/ArialMT.eot");
  src: url("../fonts/ArialMT.eot?#iefix") format("embedded-opentype"),
    url("../fonts/ArialMT.woff2") format("woff2"),
    url("../fonts/ArialMT.woff") format("woff"),
    url("../fonts/ArialMT.ttf") format("truetype"),
    url("../fonts/ArialMT.svg#ArialMT") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cambria";
  src: url("../fonts/Cambria.eot");
  src: url("../fonts/Cambria.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Cambria.woff2") format("woff2"),
    url("../fonts/Cambria.woff") format("woff"),
    url("../fonts/Cambria.ttf") format("truetype"),
    url("../fonts/Cambria.svg#Cambria") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Cambria Bold";
  src: url("../fonts/Cambria-Bold.eot");
  src: url("../fonts/Cambria-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Cambria-Bold.woff2") format("woff2"),
    url("../fonts/Cambria-Bold.woff") format("woff"),
    url("../fonts/Cambria-Bold.ttf") format("truetype"),
    url("../fonts/Cambria-Bold.svg#Cambria-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PremiumUltra16";
  src: url("../fonts/PremiumUltra16.eot");
  src: url("../fonts/PremiumUltra16.eot?#iefix") format("embedded-opentype"),
    url("../fonts/PremiumUltra16.woff2") format("woff2"),
    url("../fonts/PremiumUltra16.woff") format("woff"),
    url("../fonts/PremiumUltra16.ttf") format("truetype"),
    url("../fonts/PremiumUltra16.svg#PremiumUltra16") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Bold";
  src: url("../fonts/Futura-Bold.eot");
  src: url("../fonts/Futura-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Futura-Bold.woff2") format("woff2"),
    url("../fonts/Futura-Bold.woff") format("woff"),
    url("../fonts/Futura-Bold.ttf") format("truetype"),
    url("../fonts/Futura-Bold.svg#Futura-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tragic Marker TT";
  src: url("../fonts/TragicMarker-Regular.eot");
  src: url("../fonts/TragicMarker-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/TragicMarker-Regular.woff2") format("woff2"),
    url("../fonts/TragicMarker-Regular.woff") format("woff"),
    url("../fonts/TragicMarker-Regular.ttf") format("truetype"),
    url("../fonts/TragicMarker-Regular.svg#TragicMarker-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu-Regular.eot");
  src: url("../fonts/Ubuntu-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Ubuntu-Regular.woff2") format("woff2"),
    url("../fonts/Ubuntu-Regular.woff") format("woff"),
    url("../fonts/Ubuntu-Regular.ttf") format("truetype"),
    url("../fonts/Ubuntu-Regular.svg#Ubuntu-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Broetown Signature";
  src: url("../fonts/BroetownSignature.eot");
  src: url("../fonts/BroetownSignature.eot?#iefix") format("embedded-opentype"),
    url("../fonts/BroetownSignature.woff2") format("woff2"),
    url("../fonts/BroetownSignature.woff") format("woff"),
    url("../fonts/BroetownSignature.ttf") format("truetype"),
    url("../fonts/BroetownSignature.svg#BroetownSignature") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
