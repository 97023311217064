.add-Product-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.add-Product-block .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.add-Product-block .form-group {
  margin-bottom: 8px;
}
.add-Product-block .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.add-Product-block .titel {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.add-Product-block .affiliate-block {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.add-Product-block .affiliate-block .affiliate-titel {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.add-Product-block .affiliate-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 8px 16px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  background: #495057;
  border: none;
  outline: none;
  font-size: 14px;
}

.add-Product-block .affiliate-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.yes-no-redio-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.yes-no-redio-block .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yes-no-redio-block .yes-no-redio-item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.yes-no-redio-block .yes-no-redio-item span {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px;
  margin: 0;
  border: none;
  outline: none;
}
.yes-no-redio-block .yes-no-redio-item .custom-radio {
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
}
.yes-no-redio-block .yes-no-redio-item .custom-control-label {
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
}
.yes-no-redio-block .yes-no-redio-item .custom-control-label::before,
.yes-no-redio-block .yes-no-redio-item .custom-control-label::after {
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
}

.price-range-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.price-range-block .form-group {
  width: 100%;
  padding: 0;
  margin: 0 0 8px 0 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  outline: none;
}
.price-range-block .form-group .form-control {
  max-width: 90px;
  margin-right: 8px;
  padding: 8px !important;
  height: auto;
}

.add-Product-block .radio-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 0 0;
  margin: 0;
  border: none;
}
.add-Product-block .radio-block legend {
  width: auto;
  display: inline-block;
  padding: 0 15px 0 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.add-Product-block .radio-block .form-group {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  border: none;
}
.add-Product-block .radio-block .form-check {
  margin-right: 15px;
}
.add-Product-block .radio-block .form-check label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}
.add-Product-block .radio-block .form-check-input {
  width: 22px;
  height: 22px;
  position: static;
  margin-right: 8px;
}
.add-Product-block .radio-block .form-group legend {
  width: auto;
  display: inline-block;
  padding: 0 8px 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.add-Product-block .update-button-block {
  width: 100%;
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-Product-block .update-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.submit-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.addproduct-right-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.addproduct-right-block .product-image-preview-box {
  width: 100%;
  padding: 0;
  margin: 0 0 20px 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.addproduct-right-block .product-image-preview-box img {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  height: auto;
}

/*////////*/

.affiliate-popup-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.affiliate-popup-block .affiliate-all-list {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  max-height: 300px;
  overflow: auto;
}
.affiliate-popup-block .affiliate-all-list .form-check {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.affiliate-popup-block .affiliate-all-list .form-check .form-check-input {
  display: none;
}
.affiliate-popup-block .affiliate-all-list .form-check .form-check-label {
  width: 100%;
  background-color: #f2f2f2;
  padding: 8px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.affiliate-popup-block .affiliate-all-list [type="checkbox"]:checked + label {
  background-color: #545cd8;
  color: #ffffff;
}

.select-all-affiliates {
  width: 100%;
  padding: 20px 0 0 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.select-all-affiliates .form-check {
  padding-left: 0;
}
.select-all-affiliates .form-check label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

/*///////*/

.add-extraform-block {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.add-extraform-block .card {
  margin-bottom: 16px;
  box-shadow: 0 0.16875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.20625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.33125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1575rem rgb(8 10 37 / 3%);
  border-width: 0;
}

.add-extraform-block .card .form-control {
  background-color: transparent;
}

.add-extraform-block .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  background-color: rgba(0, 0, 0, 0.1);
  color: #545cd8;
}
.add-extraform-block .card-body {
  padding: 8px 16px;
}

/*////////payment-processing-block/////*/

.payment-processing-block .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #fafbfc;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.payment-processing-form-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-bottom: 30px;
}

.oneline-redio-block {
  width: 100%;
  padding: 0 0 5px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.oneline-redio-block .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.oneline-redio-block .form-group .oneline-left-titel {
  display: inline-block;
  max-width: 190px;
  font-weight: bold;
}
.oneline-redio-block .oneline-redio-list {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-left: 10px;
}
.oneline-redio-block .oneline-redio-list .custom-control {
  padding-left: 0;
  margin-right: 15px;
}
.oneline-redio-block .oneline-redio-list .custom-control-label {
  padding-left: 30px;
}
.oneline-redio-block .oneline-redio-list .custom-control-label::before {
  left: 0;
}
.oneline-redio-block .oneline-redio-list .custom-control-label::after {
  left: 0;
}

.status-form-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-top: 15px;
}
.status-form-box .oneline-redio-block .form-group .oneline-left-titel {
  width: auto;
  max-width: inherit;
}

/*///////*/

.add-Product-block #scaleBoxLayout .design-thum-block .thum-box {
  min-width: inherit;
  min-height: inherit;
}
.add-Product-block #scaleBoxLayout .design-thum-block {
  padding: 0;
}

.add-Product-block #scaleTemplateBoxLayout .design-thum-block .thum-box {
  width: 180px;
  height: 180px;
}
.add-Product-block #scaleTemplateBoxLayout .design-thum-block {
  padding: 0;
}

.add-Product-block .upload-btn-box .upload-btn-wrapper {
  padding: 0;
}
.add-Product-block .upload-btn-box .upload-btn-wrapper input[type="file"] {
  cursor: pointer;
}
.add-Product-block .upload-btn-box .upload-btn-wrapper .upload-button {
  cursor: pointer;
}
.add-Product-block .upload-btn-box .upload-btn-wrapper .upload-button .btn {
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 8px 15px;
  color: #fff;
  cursor: pointer;
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 16px;
}

.add-Product-block #scaleBoxLayout .design-thum-block .upload-btn-box {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
  border-width: 0;
  transition: all 0.2s;
  position: relative;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem;
  margin-bottom: 30px;
}

.add-Product-block
  #scaleBoxLayout
  .design-thum-block
  .upload-btn-box
  .remove-image-button {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  padding: 4px;
  margin: 0;
  font-size: 24px;
  line-height: 24px;
  z-index: 9999;
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
  border-width: 0;
}

/*////////*/

.addnew-form-button {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
}
.addnew-form-button .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  height: 35px;
  background: #545cd8;
  border: none;
  outline: none;
  box-shadow: none;
}

.add-extraform-block .extraform-button-block {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-extraform-block .extraform-button-block .removed-b {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.removed-b .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: none;
  height: 35px;
  background: #495057;
  border: none;
  outline: none;
}

.add-extraform-block .image-preview-box {
  display: flex;
  width: 80px;
  height: 80px;
  border: none;
  outline: none;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 0;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: none;
  background: #495057;
  border: none;
  outline: none;
  text-align: center;
  overflow: hidden;
}
.add-extraform-block .image-preview-box img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.form-control-file::-webkit-file-upload-button {
  visibility: hidden;
}
.form-control-file::before {
  content: "Upload Image";
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 25px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  height: auto;
  background: #495057;
  border: none;
  outline: none;
}
.form-control-file:hover::before {
  border-color: black;
}
.form-control-file:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.add-Product-block .oneline-redio-block {
  padding-top: 8px;
}

.add-Product-block .oneline-redio-block .form-group .oneline-left-titel {
  display: inline-block;
  max-width: inherit;
  font-weight: bold;
  width: auto;
}

.modal-dialog.create-price-modal {
  max-width: 620px;
}

.scroll-bar {
  overflow: auto;
  max-height: 500px;
}
