body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Arial", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*body{
  font-family: Arial;
  line-height: normal;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 1rem;
  display:block;
}
p{
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px; 
  margin-inline-end: 0px;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/** Dashboard css **/
/*body {
  font-size: .875rem;
}*/

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/*
 * Utilities
 */

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.navbar-nav li .submenu {
  width: 100%;
  padding: 0;
  margin: 0;
  bottom: none;
  outline: none;
  text-decoration: none;
  background-color: #ffffff;
  height: 0;
  opacity: 0;
  display: none;
  transition: all 0.3s ease;
  position: relative;
}

.navbar-nav li .submenu .submenu-item a {
  position: relative;
}

/*.navbar-nav li .submenu .submenu-item a:after {
  content: '»';
  position: absolute;
  opacity: 0;  
  top: 10px;
  right: 5px;
  transition: 0.5s;
}*/

.navbar-nav li .submenu .submenu-item a.nav-link.active {
  background: #e0f3ff;
  text-decoration: none;
  color: #545cd8;
}

.navbar-nav li:hover {
  text-decoration: none;
}
.navbar-nav li.active-menu-class .submenu {
  height: auto;
  display: block;
  opacity: 1;
  background: #ffffff;
}

.submenu .nav-link.active:before {
  border-left: none !important;
}

.navbar-nav li .submenu li {
  width: 100%;
  list-style: none;
}

.navbar-nav li .submenu li a {
  background-color: transparent;
}

.navbar-nav li .submenu li a:hover {
  text-decoration: none;
  background-color: transparent;
}

/*//////new- 2021.07.28/////////*/

.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
  max-width: 100%;
  max-height: inherit !important;
}

.main-content {
  background-color: #eff3f6;
}
body {
  background-color: #eff3f6;
}
.main-content main {
  background-color: #eff3f6;
  min-height: 80vh;
  padding-top: 70px !important;
}
footer {
  background: #eff3f6 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: none !important;
}

.main-top-header {
  width: 100%;
  padding: 4px 15px;
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafbfc;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
}

.main-top-header .main-top-header-left {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.main-top-header .main-top-header-left a {
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.main-top-header .main-top-header-left img {
  max-height: 50px;
  width: auto;
  padding: 0;
  margin: 0;
  border: none;
}

.main-top-header .main-top-header-right {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

#sidenav-main .container-fluid {
  padding-top: 35px;
}

/*/////menu///*/
.navbar-nav li.active-menu-class .submenu {
  padding: 0.5em 0 0 2rem;
  transition: padding 0.3s;
  list-style: none;
}

.navbar-nav li.active-menu-class .submenu:before {
  content: "";
  height: 100%;
  opacity: 1;
  width: 3px;
  background: #e0f3ff;
  position: absolute;
  left: 20px;
  top: 0;
  border-radius: 15px;
}
.navbar-nav li.dropdown > a {
  position: relative;
}
.navbar-nav li.dropdown > a:after {
  content: "\ea0f";
  position: absolute;
  opacity: 10;
  top: 18px;
  right: 15px;
  transition: 0.5s;
  font: normal normal normal 11px/1 NucleoIcons;
  font-weight: normal;
  opacity: 0.5;
  transition: transform 0.3s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar-nav li.dropdown.active-menu-class > a:after {
  opacity: 0.9;
  transform: rotate(-180deg);
}

.navbar-nav li .submenu .submenu-item a {
  height: 2.3em;
  line-height: 2.3em;
  color: #6c757d;
  margin: 0 0 3px;
}

.navbar-nav li .submenu .submenu-item a:hover {
  background: #e0f3ff;
  text-decoration: none;
  color: #545cd8;
}

/*/////////*/

.main-content main .header {
  background: transparent !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/*/////////*/

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
  border-width: 0;
  transition: all 0.2s;
  position: relative;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem;
}

.tabile-main-block .table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.tabile-main-block .table thead th {
  border-bottom: 1px solid #e9ecef;
  font-size: 14px;
  font-weight: bold;
}

.tabile-main-block .table thead {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e9ecef;
  padding: 0.55rem;
  line-height: normal;
  position: relative;
  font-weight: 700;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  color: #545cd8;
}

.tabile-main-block .table tbody tr {
  border-bottom: 1px solid #e9ecef;
}

.tabile-main-block .table tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.tabile-main-block .table td {
  border-right: 1px solid #e9ecef;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #495057;
  text-align: left;
  word-wrap: break-word;
  padding: 5px 15px;
  vertical-align: middle;
}

.tabile-main-block .table .tem-action .btn {
  display: inline-block;
  font-weight: 400;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: initial;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 19px;

  outline: none !important;
  font-size: 0.8rem;
  font-weight: 500;

  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8;

  position: relative;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s,
    box-shadow 0.15s;
}

.tabile-main-block .table .tem-action .tem-edit {
  color: #fff;
  background-color: #545cd8;
  border-color: #545cd8;
  font-size: 16px;
}

.tabile-main-block .table .tem-action .tem-delete {
  color: #fff;
  background-color: #d92550;
  border-color: #d92550;
  font-size: 16px;
}

.pagination-bottom-block {
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  color: #545cd8;
  background: #f8f9fa;
  transition: all 0.2s ease;
  font-weight: 700;
}

.pagination-bottom-block .pagination {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.breadcrumb-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.breadcrumb-block nav {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.breadcrumb-block .breadcrumb {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 0.88rem;
  opacity: 0.6;
  background-color: transparent;
  position: relative;
  margin-top: -20px;
  padding-bottom: 8px;
}
.breadcrumb-block .breadcrumb a {
  text-decoration: none;
  color: #495057;
}

.main-heading-block {
  padding: 30px;
  margin: -15px -30px 30px -30px;
  position: relative;
  background: hsla(0, 0%, 100%, 0.55);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.main-heading-block .header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  color: #495057;
  font-weight: 400;
  line-height: normal;
}

.main-heading-block .header-title .header-icon {
  font-size: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 0.83333rem;
  margin: 0 30px 0 0;
  background: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
  border-radius: 0.25rem;
  width: 60px;
  height: 60px;
  color: #545cd8;
  font-weight: 400;
  line-height: normal;
}
.navbar-vertical .navbar-nav .nav-item {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
}
.navbar-vertical .navbar-nav .nav-link {
  transition: transform 0.2s, height 0.3s, color 0.3s, background-color 0.3s;
  display: block;
  line-height: 2.5rem;
  height: 2.5rem;
  padding: 0 1.5rem 0 55px !important;
  position: relative;
  border-radius: 0.25rem;
  color: #343a40;
  white-space: nowrap;
  text-align: left;
}
.navbar-vertical .navbar-nav .nav-link > i {
  color: #343a40 !important;
  opacity: 0.3;
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -17px;
  font-size: 1.3rem;
  opacity: 0.3;
  transition: color 0.3s;
}

.navbar-vertical .navbar-nav .nav-link:hover > i {
  color: #343a40 !important;
  opacity: 1;
}

.navbar-vertical .navbar-nav .nav-link.active > i {
  color: #343a40 !important;
  opacity: 1;
}

.navbar-vertical .navbar-nav .submenu .submenu-item a {
  padding-left: 1.5em !important;
  text-align: left;
}
.table-template-form-block .form-group {
  position: relative;
  margin-bottom: 30px;
}
.table-template-form-block .form-group .red_err {
  color: #d92550;
  font-weight: bold;
  position: absolute;
  bottom: -10px;
  left: 15px;
  font-size: 12px;
  background: #fff;
  padding: 4px 8px;
  font-weight: normal;
  margin: 0;
}

.table-vdp-merge .form-group {
  position: relative;
  margin-bottom: 30px;
}
.table-vdp-merge .form-group .red_err {
  color: #d92550;
  font-weight: bold;
  position: absolute;
  bottom: -10px;
  left: 15px;
  font-size: 12px;
  background: #fff;
  padding: 4px 8px;
  font-weight: normal;
  margin: 0;
}

.login-main-block {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.login-main-block label.title {
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.login-main-block .card {
  width: 100%;
  max-width: 460px;
  margin-bottom: 30px;
}

.login-main-block .card .form-group {
  position: relative;
  margin-bottom: 30px;
}

.login-main-block .card .form-group .red_err {
  color: #d92550;
  font-weight: bold;
  position: absolute;
  bottom: -10px;
  left: 15px;
  font-size: 12px;
  background: #fff;
  padding: 4px 8px;
  font-weight: normal;
  margin: 0;
}

/*///////*/
.table-category-form-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.table-category-form-block .form-group {
  position: relative;
  margin-bottom: 30px;
}

.table-category-form-block .form-group .red_err {
  color: #d92550;
  font-weight: bold;
  position: absolute;
  bottom: -10px;
  left: 15px;
  font-size: 12px;
  background: #fff;
  padding: 4px 8px;
  font-weight: normal;
  margin: 0;
  width: auto;
}

.form-group {
  position: relative !important;
}
.form-group .red_err {
  color: #d92550 !important;
  font-weight: bold !important;
  position: absolute;
  bottom: -10px;
  left: 15px !important;
  font-size: 12px !important;
  background: #fff !important;
  padding: 4px 8px !important;
  font-weight: normal;
  margin: 0 !important;
  width: auto !important;
}

.table-category-form-block label {
  font-weight: 600;
}
.table-category-form-block .form-group.status-block {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.table-category-form-block .redia-check-box {
  display: flex;
  padding: 0 4px;
  margin: 0;
  border: none;
  line-height: 24px;
  align-items: center;
}

.table-category-form-block .form-group input[type="radio"],
input[type="checkbox"] {
  width: auto;
  display: inline-block;
  padding: 0;
  margin: 0 4px !important;
  border: none;
  outline: none;
  height: auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.save-change-button-block {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.save-change-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.save-change-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  font-size: 0.875rem;
}
.red-color {
  color: #d92550 !important;
}

/*//////left-scoll-bar///*/

#sidenav-main {
  scrollbar-width: thin;
  scrollbar-color: #c3c4c6 #ffffff;
}

#sidenav-main::-webkit-scrollbar {
  width: 12px;
}

#sidenav-main::-webkit-scrollbar-track {
  background: #ffffff;
}

#sidenav-main::-webkit-scrollbar-thumb {
  background-color: #c3c4c6;
  border-radius: 20px;
  border: 3px solid #ffffff;
}

/*//////left-scoll-bar///*/

.btn-warning {
  color: #212529 !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #545b62 !important;
  border-color: #4e555b !important;
}
.delete-saved-cards {
  display: float-right;
  background-color: rgb(199, 43, 43) !important;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
}

.delete-user-cards {
  display: float-right;
  background-color: rgb(199, 43, 43) !important;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
}

.delete-saved-card-setting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accutxt-info-modal {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin: 10px;
  background-color: #f1eded52;
}

.accutxt-info-button {
  margin: 5px;
  text-align: center;
  padding: 5px;
  width: 20%;
}

.accutxt-text-response {
  text-align: center;
  font-size: large;
  padding: 5px;
  margin-bottom: 5px;
}

.accutxt-modal-form {
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.accutext-response-select {
  width: 85%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  margin: 10px;
}

.accutext-response-textfield {
  width: 85%;
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  color: #8898aa;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  margin: 10px;
}
.accutxt-input-modal {
  width: 100%;
  padding: 5px;
  margin-left: 28px;
}

.list-filtered-table-block .table-min-height {
  min-height: 380px !important;
}

.cc-payment-method {
  max-width: 100% !important;
}

.void-processing-fee-class {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
  margin: 0px;
  text-align: left;
}

.void-processing-fee-class .confirm-order-button {
  width: auto;
  display: flex;
  justify-self: flex-start;
  align-items: center;
  padding: 10px;
  margin: 0px;
}

.wallet-payment-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0px;
  margin: 0px;
}

.wallet-payment-block .ach-payment-box {
  width: 100%;
  padding-right: 10px;
}

.wallet-payment-block .ach-payment-box .pmbox {
  width: 100%;
  max-width: 100%;
  min-height: 340px;
  max-height: 350px;
  overflow: auto;
}

.cc-payment-method table tr,
.cc-payment-method table td {
  vertical-align: middle;
}

.color-picker-attribute {
  width: 100%;
  border-radius: 5px;
  height: 42px;
  border: #cbd1d7 solid 1px;
  padding: 3px;
}

.set-defaut-height {
  min-height: 450px;
}

.list-filtered-table-block {
  min-height: 390px;
}
