.order-main-block {
  width: 100%;
  padding: 0;
  margin: 0 0 30px 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.order-main-block .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #fafbfc;
  font-size: 16px;
}
.order-main-block .card-body {
  padding: 1.25rem;
}

.order-main-block .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 18px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.order-main-block .card-header .btn {
  box-sizing: border-box;
  position: relative;
  display: block;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 8px 20px;
  color: #545cd8;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: none;
  height: 35px;
  background: #fff;
  border: none;
  outline: none;
}

/*///////create-order-box////////*/

.create-order-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-bottom: 30px;
}
.create-order-button-block {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-order-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.create-order-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.modal-button-block {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.modal-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.modal-button-block.confirm-button .btn {
  color: #fff;
  background-color: #3ac47d;
  border-color: #3ac47d;
  box-shadow: none;
}

.modal-button-block .btn.btn-secondary {
  background-color: #495057;
  border-color: #495057;
  color: #ffffff;
}

.or-block {
  width: 100%;
  padding: 0;
  margin: 16px 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.or-block::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #525f7f;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1px;
  opacity: 0.5;
}
.or-block span {
  background-color: #fff;
  padding: 8px 16px;
  color: #495057;
  position: relative;
  z-index: 9;
  font-weight: 600;
}

.select-user-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  margin-bottom: 30px;
}

.order-main-block .button-block {
  width: 100%;
  padding: 15px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.order-main-block .button-block .btn {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.ordedr-details-listbox {
  min-height: 330px;
}

.add-to-cart-block {
  width: 100%;
  padding: 15px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-to-cart-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background-color: #31a66a;
  border-color: #2e9d64;
  border: none;
  outline: none;
  font-size: 18px;
}

.add-to-cart-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.search-input {
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.search-input button {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  right: 15px;
  z-index: 9;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  font-family: "Font Awesome 5 Free";
  margin-top: -9px;
  cursor: pointer;
  background: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #495057 !important;
}

.order-tabile-block .rdt_Table .domoiu .btn {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: bold;
  color: #545cd8 !important;
}

/*/ ////*/

.new-campaign-name-block {
  width: 100%;
  padding: 0 0 30px 0;
  margin: 0;
  border: none;
  text-align: center;
}

.new-campaign-name-text {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  text-align: center;
}

.order-main-block .add-product-button-block {
  width: 100%;
  padding: 15px 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.next-button-block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.small_text {
  font-size: x-small;
}

/*++++++++++++++++ Payment +++++++++++++++++++++++++*/
.leftSection,
.rightSection {
  width: 22%;
  padding: 0 15px;
}
.centerDiv {
  width: 56%;
  max-width: 1000px;
  margin: 20px auto;
}
.paymentMethod {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  margin: 0;
}
.paymentMethod h4 {
  width: 100%;
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: bold;
  line-height: normal;
}

.pmbox {
  padding: 15px;
  box-shadow: 0 0 10px #00000021;
  border-radius: 20px;
  margin: 20px 0;
  width: 100%;
  max-width: 350px;
}
.paymentfooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.paymentfooter a {
  color: #707070;
}
.paymentfooter a:hover {
  color: #000;
  outline: none;
  text-decoration: none;
}
.paymentfooter a.addIcon i {
  color: #ed1c24;
  font-size: 23px;
  font-style: normal;
  position: relative;
  top: 2px;
  line-height: 12px;
}
.paymentfooter a.otherPay i {
  margin-left: 5px;
  color: #edbd1c;
}
.pmbox ul {
  padding: 0;
  list-style: none;
}
.pmbox ul li {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  margin: 8px 0;
}
.pmbox ul li p {
  margin: 0;
  color: #707070;
  font-size: 11px;
  line-height: 12px;
}
.pmbox ul li h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  color: #707070;
  line-height: normal;
}
.pmbox ul li img {
  margin-right: 17px;
  max-width: 100px;
}
.pmbox .table th i.fa-sort {
  margin-left: 5px;
}
.yellowColor {
  color: #edbd1c !important;
}
.mt-4 {
  margin-top: 1.2em;
}
.mt-5 {
  margin-top: 2em;
}
.customHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.customHead a,
.reiRedBtn {
  min-width: 150px;
  background-color: #ed1c24;
}

.fullWrap {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.from-field {
  margin-bottom: 10px;
}
.from-field input[type="number"] {
  width: 100%;
  border: none;
  box-shadow: 0 0 5px #00000021;
  height: 34px;
  padding: 0 15px;
  border-radius: 5px;
}
.radioAuto {
  font-weight: 500;
}
.radioAuto input {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.action-div {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}
.action-div span {
  margin-right: 10px;
}

.upload-batch-preview-csv {
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  color: #545cd8;
}

/*//////*/
.pmbox ul li {
  cursor: pointer;
}
.pmbox.payment-conform-box {
  margin-left: 30px;
}
.pmbox ul li.active {
  background: #ffc400;
  box-shadow: 1px 0 4px #00000030;
}
.paymentfooter a {
  font-size: 13px;
}
.paymentfooter a.otherPay i {
  margin-left: 2px;
}

.payment-card-details {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 6px;
  margin: 2px 0;
  cursor: pointer;
}
.payment-card-details p {
  margin: 0;
  color: #707070;
  font-size: 11px;
  line-height: 12px;
}
.payment-card-details h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  color: #707070;
  line-height: normal;
}
.payment-card-details img {
  margin-right: 17px;
  max-width: 100px;
}

.payment-conform-box .header-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  padding-bottom: 4px;
  position: relative;
}
.payment-conform-box .removed-button button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  background: transparent;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #ed1c24 !important;
  font-size: 11px !important;
  font-weight: bold;
  line-height: normal;
}
.payment-conform-box .removed-button button i {
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.payment-conform-box .removed-button button span {
  display: block;
  padding: 0;
  margin: 0;
  font-size: 10px;
}
.payment-conform-box .header-block h4 {
  font-size: 16px;
}

.payment-conform-box .payment-billing-address {
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
  border: none;
  outline: none;
  text-decoration: none;
  padding-top: 8px;
}

.payment-conform-box .payment-billing-address h5 {
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 14px;
}
.payment-conform-box .payment-billing-address .billing-form-block {
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
  border: none;
  outline: none;
  text-decoration: none;
  padding-top: 4px;
}
.payment-conform-box .billing-form-block .form-control {
  width: 100%;
  border-radius: 0;
  border: none;
  outline: none !important;
  border-bottom: 1px solid #c0c0c0;
  box-shadow: none !important;
  background: transparent;
  padding: 0;
  font-size: 13px;
}
.payment-conform-box .billing-form-block .row {
  margin-right: -4px;
  margin-left: -4px;
}
.payment-conform-box .billing-form-block .col-sm-4 {
  padding-right: 4px;
  padding-left: 4px;
}

.confirm-order-button {
  width: 100%;
  padding: 8px 0;
  margin: 0;
  clear: both;
  border: none;
  outline: none;
  text-decoration: none;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-order-button .btn {
  background-color: #ed1c24 !important;
  border-color: #ed1c24 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-order-button .btn i {
  padding: 0 4px 0 8px;
}

.payment-conform-box .payment-billing-address .billing-form-block .row {
  margin-right: -4px;
  margin-left: -4px;
}
.payment-conform-box .payment-billing-address .billing-form-block .col-sm-6,
.payment-conform-box .payment-billing-address .billing-form-block .col-sm-2,
.payment-conform-box .payment-billing-address .billing-form-block .col-sm-4,
.payment-conform-box .payment-billing-address .billing-form-block .col-sm-3 {
  padding-right: 4px;
  padding-left: 4px;
}

.payment-conform-box .add-card-button-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-conform-box .add-card-button-block .form-check {
  padding-left: 0;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.payment-conform-box .add-card-button-block .form-check label {
  font-size: 12px;
}
.payment-conform-box .add-card-button-block .form-check input[type="radio"],
input[type="checkbox"] {
  position: static;
  width: 14px;
  height: 14px;
  padding: 0;
  margin: 0 4px 0 0 !important;
}

.payment-conform-box .add-card-button-block .btn {
  display: inline-block;
  background-color: #f3c000 !important;
  border-color: #f3c000 !important;
  color: #484848 !important;
  padding: 8px 16px;
}

.payment-selected {
  border: solid 1px #c0c0c0;
}

.delete-drop-btn,
.complete-batch-btn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0;
  padding-left: 10px;
}

.complete-batch-btn .cancel-batch-btn {
  color: #fff !important;
  background-color: #545b62 !important;
  border-color: #4e555b !important;
}

.csv-download-btn {
  color: #545cd8;
  padding: 2px;
}
.delete-batch-btn {
  color: #ff0000;
  padding: 2px;
}
.list-filtered-table-block
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow:hover
  .csv-download-btn
  a {
  text-decoration: none;
  color: #ffffff !important;
}
.list-filtered-table-block
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow:hover
  .delete-batch-btn {
  text-decoration: none;
  color: #ffffff;
}

.create-batch-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.create-batch-btn .btn-secondary {
  color: #fff !important;
  background-color: #545cd8 !important;
  border-color: #545cd8 !important;
}

.batch-preview-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow div:last-child,
.batch-preview-table .rdt_Table .rdt_TableRow div:last-child {
  justify-content: center;
}

.batch-preview-table .orderlist-details-block .orderlist-item-box .list-item {
  width: 100%;
}
.checbox-first-row
  .orderlist-details-block
  .orderlist-item-box
  .list-item:first-child {
  max-width: 50px;
}

.batch-action {
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-box div .select_user {
  padding: 4px 8px;
}
.result-box div .select_user:hover {
  text-decoration: none;
  background: #495057 !important;
  cursor: pointer;
  color: #ffffff !important;
}

.add-product-to-order-modal
  .choose-design-main-block
  .choose-design-block
  .choose-all-products-box-main {
  max-height: 65vh;
}
.add-product-to-order-modal .choose-design-main-block .sortby-all-listbox {
  max-height: 55vh;
}

/*/////////*/

.user-wallet-balance-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-top: 20px;
}

.user-wallet-balance-block .user-wallet-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.user-wallet-balance-block .user-wallet-box .pmbox {
  margin: 0;
}
.user-wallet-balance-block .user-wallet-box .payment-card-details {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user-wallet-box .payment-card-details .user-balance-box {
  display: flex;
  width: 62px;
  height: 62px;
  background-color: #edbd1c;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 11px;
  text-align: center;
  line-height: 13px;
  color: #000;
  font-weight: 600;
}
.user-wallet-box .payment-card-details .blance-text {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  padding-left: 15px;
  font-size: 18px;
}
.user-wallet-box .payment-card-details .blance-text h5 {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  font-size: 24px;
}
.user-wallet-balance-block .user-wallet-box .confirm-order-button {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pmbox.wallet-selected {
  border: solid 1px #a88923;
}

.searchfile-list-block .row {
  margin-right: 0;
  margin-left: 0;
}
.searchfile-list-block .row .col-sm-8,
.searchfile-list-block .row .col-sm-4 {
  padding-right: 0;
  padding-left: 0;
}
.searchfile-list-block .upload-button {
  width: 100%;
}
.searchfile-list-block .upload-button .btn {
  width: 100%;
}

.btn-css {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.btn-css .cust-btn {
  background-color: #ed1c24 !important;
  border-color: #ed1c24 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem 1.25rem;
  font-size: 16px;
  cursor: pointer;
}

.btn-css .upload-button {
  width: auto;
  display: inline-block;
  padding: 0;
  margin: 0;
  position: relative;
}

.btn-css .upload-button input#uplodedFile {
  position: absolute;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  transition: inherit;
  top: 0;
  left: 0;
}

/* .batch-preview-file-input {
  opacity: 0;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 100%;
} */

.list-filtered-table-block
  .rdt_Table
  .rdt_TableBody
  .rdt_TableRow:hover
  .upload-batch-preview-csv {
  color: #ffffff;
}

.accutxt-button {
  margin: 3px;
  background-color: rgb(245 22 22);
  padding: 8px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.header-upload {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}

input.header_uplodedFile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
}

.set-defaut-height {
  min-height: 450px;
}

.orderhistory-tabile-block .list-filtered-table-block header {
  display: block;
  padding-bottom: 8px;
}
