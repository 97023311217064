.subscription-main-box {
  width: 100%;
  padding: 0;
  margin: 0 0 30px 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.subscription-main-box .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
}
.subscription-main-box .card-body {
  padding: 1.25rem;
}

.subscription-main-box .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.subscription-main-box .card-header .btn {
  box-sizing: border-box;
  position: relative;
  display: block;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 8px 20px;
  color: #545cd8;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: none;
  height: 35px;
  background: #fff;
  border: none;
  outline: none;
}

.subscription-main-box .table {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.subscription-main-box .table thead th {
  border-bottom: 1px solid #e9ecef;
  font-size: 14px;
  font-weight: bold;
}

.subscription-main-box .table thead {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e9ecef;
  padding: 0.55rem;
  line-height: normal;
  position: relative;
  font-weight: 700;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  color: #545cd8;
}

.subscription-main-box .table tbody tr {
  border-bottom: 1px solid #e9ecef;
}

.subscription-main-box .table tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}

.subscription-main-box .table td {
  border-right: 1px solid #e9ecef;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #495057;
  text-align: left;
  word-wrap: break-word;
  padding: 8px 15px !important;
  vertical-align: middle;
  vertical-align: middle;
}
.subscription-main-box .table td:last-child {
  text-align: right;
}
.subscription-main-box .table th {
  font-weight: bold;
  padding: 5px 15px !important;
  vertical-align: middle;
}

.subscription-main-box .table .dropdown .btn {
  display: inline-block;
  padding: 2px 8px;
  margin: 0;
  border: none !important;
  outline: none;
  text-decoration: none;
  color: #495057;
  box-shadow: none;
  background-color: transparent !important;
}

.add-drop-button-block {
  width: 100%;
  padding: 0.8rem 1.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}

.add-drop-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 8px 20px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  height: 35px;
  background: #545cd8;
  border: none;
  outline: none;
}

.add-drop-button-block .btn i {
  font-size: 18px;
  padding-right: 4px;
}

/*/////print-profile///*/

.add-subscription-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.add-subscription-block .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
}
.add-subscription-block .card-body {
  padding: 1.25rem;
}

.add-subscription-block .card-header .card-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.add-subscription-block .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.add-subscription-block .form-radio-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.add-subscription-block .form-radio-block .form-check {
  margin-right: 15px;
}

.submit-button-block {
  width: 100%;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.submit-button-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.submit-button-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.print-profile-right-block {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: right;
}

/*////select-template////*/

.select-template-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.select-template-block .template-box {
  width: 100%;
  padding: 8px;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.select-template-block .template-box a {
  display: inline-block;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}
.select-template-block .template-box .card {
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.select-template-block .template-box a:hover .card {
  text-decoration: none;
  background-color: hsla(0, 0%, 100%, 0.7);
  box-shadow: inherit;
  border: inherit;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%);
}
.select-template-block .template-box .card .card-body {
  padding: 8px;
  background-color: transparent;
  text-align: center;
}

.select-template-block .template-box .card .card-title {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

/*/////////*/

.template-preview-block {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.template-preview-main-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.template-preview-block .card {
  padding: 1.25rem;
}

.template-preview-block .template-name {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.template-preview-block .template-name label {
  width: 100%;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.template-preview-block .template-block {
  width: 100%;
  padding: 0 0 10px 0;
  margin: 0;
  border: none;
  outline: none;
}

.template-preview-block .template-box {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.template-preview-block .template-box .titel {
  width: 100%;
  font-size: 20px;
  color: #495057;
  font-weight: bold;
  line-height: normal;
  text-align: center;
}

.template-preview-block .template-box .card {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.template-preview-block .template-box .card-img-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
}

.template-preview-block .template-box:hover .card-img-overlay {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.place-order-block {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.place-order-block .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.place-order-block .btn i {
  font-size: 14px;
  padding-left: 4px;
}

.template-preview-block .template-box .upload-button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}

.template-preview-block .template-box .upload-button input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0 !important;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  cursor: pointer;
}

.template-preview-block .template-box .card-img-overlay .btn {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1) 0s;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  transform: translateZ(0);
  line-height: 1.3;
  border-radius: 0.25rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  background: #545cd8;
  border: none;
  outline: none;
  font-size: 18px;
}

.template-preview-block .template-box .card-img-overlay .btn:hover,
.template-preview-block .template-box .upload-button:hover .btn {
  text-decoration: none;
  background: #003399;
}

.form-control option {
  font-size: 16px;
  line-height: normal;
}
.subName {
  color: red;
}
.warning {
  color: #f5bd1f;
}

/*//////////*/

.modal-dialog.create-user-modal {
  max-width: 940px;
}

.modal-dialog.create-user-modal .main-heading-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.25rem;
  background-color: #eff3f6;
}

.modal-dialog.create-user-modal .main-heading-block .header-title {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.create-user-formblock {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  text-decoration: none;
}

.create-user-formblock .form-group {
  margin-bottom: 8px;
}

.create-user-formblock label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.create-user-formblock legend {
  width: 100%;
  padding: 0 0 8px 0 !important;
  margin: 0 !important;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}

.create-user-formblock .submit-button-block {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-user-formblock .form-group .set-sales-formbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}
.create-user-formblock .form-group .set-sales-formbox .form-check {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
}
.create-user-formblock .form-group .set-sales-formbox .form-check-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
}

.create-user-formblock .form-group .set-sales-formbox .form-check-input {
  position: static;
  margin-top: 0;
  margin-left: 0;
  margin-right: 4px;
}

.create-user-formblock .form-group .radio-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
}

.create-user-formblock .form-group .radio-block label {
  width: auto;
}

.create-user-formblock .form-group .radio-block label .form-check-input {
  margin-right: 4px;
}

.select-user-box .dropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  position: relative;
}

.select-user-box .dropdown button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #495057 !important;
  border: none !important;
  padding: 8px !important;
  margin: 0;
}

.modal-dialog.create-user-modal.commissions-override-modal {
  max-width: 680px;
}

.modal-dialog.create-user-modal.commissions-override-modal
  .create-user-formblock
  .submit-button-block {
  justify-content: center;
}

.commissions-override-modal
  .create-user-formblock
  .form-group
  .set-sales-formbox
  .form-check {
  flex-wrap: wrap;
  justify-content: center;
}

.commissions-override-modal
  .create-user-formblock
  .form-group
  .set-sales-formbox
  .form-check-label {
  padding: 8px 0;
  justify-content: flex-start;
  position: relative;
}

.select_user:hover {
  text-decoration: none;
  background: #2f60c2;
  cursor: pointer;
}

.box {
  box-sizing: border-box;
  border-radius: 0.45rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  border: solid;
  font-size: 15px;
  height: 40px;
  padding: 5px;
  margin: 5px;
  width: 100%;
  border-color: hsla(0, 0%, 80%, 0.7);
}
.result-box {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.45rem;
  box-shadow: 0 16px 26px -10px rgb(84 92 216 / 56%),
    0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(84 92 216 / 20%);
  border: solid;
  padding-left: 5px;
  margin: 5px;
  height: 100%;
  font-size: 20px;
  border-color: hsla(0, 0%, 80%, 0.7);
}

.popup-close {
  color: black;
  margin: auto;
  float: right;
}


/*/////*/

.add-subscription-block .right-block .select-user-box .card{
  margin-bottom: 15px;
}
.add-subscription-block .card-header .warning.card-title{
  color: #ffc107 !important;
}
.header-leftright-block .modal-title{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  color: #f5bd1f !important;
}

.price-override-modal, .price-override-modal .modal-content .modal-header, .price-override-modal .modal-content .modal-body, .price-override-modal .modal-content .modal-footer{
  background-color: #f5f5f5;
}

.price-override-modal .form-group label {
  width: 100%;
  padding: 0 0 8px 0;
  margin: 0;
  font-size: 14px;
  color: #495057;
  font-weight: 600;
  line-height: normal;
}
.price-override-modal .modal-content .modal-footer{
  justify-content: center;
}